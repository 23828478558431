import React, {useContext, useEffect} from "react"
import {Link} from "react-router-dom"
import {LangContext} from "../../App";
import {ExponatsLangPicker} from "../ExponatsLangPicker";


export const WallpaperExponat = () => {
    const i18n = useContext(LangContext)
    useEffect(() => {
        document.body.classList.add("body-home-page")
        return () => {
            document.body.classList.remove("body-home-page")
        }
    },[])
    const path = "/images/exponats/wallpaper/"
    return(
        <div className="exponat-page wallpaper-project">
            <div className="exponat-page-wrapper">
                <div className="back-arrow nav-panel">
                    <Link to="/"><img src="/images/game1/arrow-back.svg" alt="" className="hover-scale"/></Link>
                    <ExponatsLangPicker />
                </div>
                <div className="exponat-page__title">{i18n.t('Title.Wallpaper')}</div>
                <p>{i18n.t('Description.Wallpaper')}</p>
            </div>
            <div className="wallpaper-project-pictures">
                <div className="wallpaper-item">
                    <img src={path + "anastasia_evtushenko/Perfect_password_recipe_desktop.png"} alt=""/>
                    <div className="links">
                        <span>Download:</span>
                        <a href={path + "anastasia_evtushenko/Perfect_password_recipe_desktop.png"} download>Desktop</a>
                        <a href={path + "anastasia_evtushenko/Perfect_password_recipe_phone.png"} download>Mobile</a>
                    </div>
                    <p className="author">Anastasia Yevtushenko</p>
                </div>
                <div className="wallpaper-item">
                    <img src={path + "glib_kapornikov/man-final.jpg"} alt=""/>
                    <div className="links">
                        <span>Download:</span>
                        <a href={path + 'glib_kapornikov/man-final.jpg'} download>Desktop</a>
                        <a href={path + 'glib_kapornikov/man-final-2.jpg'} download>Mobile</a>
                    </div>
                    <p className="author">Glib Kaporikov</p>
                </div>
                <div className="wallpaper-item">
                    <img src={path + "maryana_mykytyuk/Digital-safety-horizontal.jpg"} alt=""/>
                    <div className="links">
                        <span>Download:</span>
                        <a href={path + "maryana_mykytyuk/Digital-safety-horizontal.jpg"} download>Desktop</a>
                        <a href={path + "maryana_mykytyuk/Digital-safety-vertical.jpg"} download>Mobile</a>
                    </div>
                    <p className="author">Maryana Mykytyuk</p>
                </div>
                <div className="wallpaper-item">
                    <img src={path + "mr_zhuravchik/desktop-1.jpg"} alt=""/>
                    <div className="links">
                        <span>Download:</span>
                        <a href={path + "mr_zhuravchik/desktop-1.jpg"} download>Desktop</a>
                        <a href={path + "mr_zhuravchik/mobile-1.jpg"} download>Mobile</a>
                    </div>
                    <p className="author">mr.zhuravchik</p>
                </div>
                <div className="wallpaper-item">
                    <img src={path + "oleksandr_grekhov/grekhov_desktop.png"} alt=""/>
                    <div className="links">
                        <span>Download:</span>
                        <a href={path + "oleksandr_grekhov/grekhov_desktop.png"} download>Desktop</a>
                        <a href={path + "oleksandr_grekhov/grekhov_mobile.png"} download>Mobile</a>
                    </div>
                    <p className="author">Oleksandr Grekhov</p>
                </div>
                <div className="wallpaper-item">
                    <img src={path + "olga_protasova/data_ctrl_desktop.png"} alt=""/>
                    <div className="links">
                        <span>Download:</span>
                        <a href={path + "olga_protasova/data_ctrl_desktop.tif"} download>Desktop</a>
                        <a href={path + "olga_protasova/data_ctrl_mobile.tif"} download>Mobile</a>
                    </div>
                    <p className="author">Olga Protasova</p>
                </div>
                <div className="wallpaper-item">
                    <img src={path + "rusaliya_abo/deskope_01.jpg"} alt=""/>
                    <div className="links">
                        <span>Download:</span>
                        <a href={path + "rusaliya_abo/deskope_01.jpg"} download>Desktop</a>
                        <a href={path + "rusaliya_abo/phone_01.jpg"} download>Mobile</a>
                    </div>
                    <p className="author">Rusaliya Abo</p>
                </div>
            </div>
        </div>
    )
}