import React from "react"
import {Link} from "react-router-dom";

export const InfoScreen = ({info, changeScreen, infoScreen, setInfoScreen, i18n}) => {
    const nextBtnHandler = () => {
        setInfoScreen(prevInfoScreen => prevInfoScreen + 1)
        changeScreen('QuestionsScreen')
    }
    return(
        <div className="game-container">
            <div className="back-arrow">
                <Link to="/"><img src="/images/game1/arrow-back.svg" alt="" className="hover-scale"/></Link>
            </div>
            <div className="container-content">
                <div className="container-content-wrapper">
                    <div className="container-title">{i18n.t("deepface:"+infoScreen+".title")}</div>
                    <p>{i18n.t("deepface:"+infoScreen+".text")}</p>
                </div>
            </div>
            <div className="btn-wrapper">
                <div
                    className="game-btn start-btn hover-scale"
                    onClick={nextBtnHandler}
                >
                    {i18n.t("common:Buttons.next")}
                </div>
            </div>
        </div>
    )
}
