import React from "react"
import Slider from "react-slick";

export const SliderScreen = ({changeScreen, sliders, slider, i18n, setPolygonText}) => {
    const slides = sliders[slider].slides
    const settings = {
        dots: true,
        initialSlide: 0,
        arrows: false,
        infinite: false,
        speed: 200,
        slidesToShow: 1,
        slidesToScroll: 1,
        beforeChange: (current, next) => {
            if(next == slides.length) {
                setPolygonText(i18n.t("quizgame:Slides."+(slider + 1)+".polygonText"))
                changeScreen('PolygonScreen')
            }
        }
    };

    return(
        <div className="game-container take-quiz-slider-container">
            <div className="back-arrow">
                <a href="/"><img src="/images/game1/arrow-back.svg" alt="" className="hover-scale"/></a>
            </div>
            <div className="container-content">
                <div className="container-content-wrapper">
                    <Slider {...settings}  className='take-quiz-slider'>
                        {slides.map((slide, index) =>
                            <div className="take-quiz-slide">
                                <div className="container-title slide-content">
                                    <pre style={{margin:0, whiteSpace:"pre-line"}}>{i18n.t("quizgame:Slides."+(slider + 1)+".slides."+(index + 1))}</pre>
                                </div>
                            </div>
                        )}
                        <div className="take-quiz-slide"></div>
                    </Slider>
                </div>
            </div>
        </div>
    )
}