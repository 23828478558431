import React, {useEffect, useRef} from "react"
import {Link} from "react-router-dom"

export const QuestionsScreen = (
    {
        i18n,
        changeScreen,
        changeStatus,
        changeQuestion,
        changePoints,
        changeGradientTop,
        changeGradientBottom,
        questionNumber,
        gradientTop,
        gradientBottom,
        questions,
        status,
        points
    }) => {

    const video = useRef(null);
    useEffect(()=> {
        video.current.play()
    },[])

    const nextQuestion = () => {
        if(questionNumber % 2 === 0) {
            changeScreen("InfoScreen")
        }
        changeQuestion(questionNumber + 1)
        changeStatus('')
    }

    const answerHandler = (status) => {
        video.current.pause()
        if(status === questions[questionNumber - 1].status) {
            changePoints(points + 1)
        }
        changeStatus(questions[questionNumber - 1].status)
    }

    const onScrollHandler = (event) => {
        const _target = event.target
        if(_target.scrollTop > 0) {
            changeGradientTop(true)
        }else {
            changeGradientTop(false)
        }

        if(_target.scrollTop === (_target.scrollHeight - _target.clientHeight)) {
            changeGradientBottom(false)
        }else {
            changeGradientBottom(true)
        }
    }

    return (
        <div className="game-container question-container news-tinder-container deep-fake">
            <div className="back-arrow nav-panel">
                <Link to="/"><img src="/images/game1/arrow-back.svg" alt="" className="hover-scale"/></Link>
                <div className="question-numbering pixel-font">{questionNumber} / 10</div>
                <div className="points">
                    <img src="/images/aiversus/menu-check.svg" alt=""/>
                    <span className="pixel-font">{points}</span>
                </div>
            </div>
            <div className="container-content deep-fake-content">
                {gradientTop && <div className="gradient top-gradient"></div>}
                <div className="container-content-wrapper" onScroll={onScrollHandler}>
                    <video controls="controls" autoplay style={{marginTop:"0"}} className="video-player" key={questions[questionNumber - 1].video} ref={video}>
                        <source src={questions[questionNumber - 1].video} type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' />
                    </video>
                </div>
                {gradientBottom && <div className="gradient bottom-gradient"></div>}
            </div>
            <div className="true-false-btn">
                <div className="game-btn false-btn hover-scale" onClick={() => answerHandler('fake')}>{i18n.t("common:Fake")}</div>
                <div className="game-btn true-btn hover-scale" onClick={() => answerHandler('really')}>{i18n.t("common:True")}</div>
            </div>
            {status === 'fake' &&
            <FakePopup
                i18n={i18n}
                link={questions[questionNumber - 1].link}
                date={questions[questionNumber - 1].date}
                description={questions[questionNumber - 1].description}
                nextQuestion={nextQuestion}
                originalVideo={questions[questionNumber - 1].originalVideo} />}
            {status === 'really' &&
            <ReallyPopup
                i18n={i18n}
                link={questions[questionNumber - 1].link}
                date={questions[questionNumber - 1].date}
                nextQuestion={nextQuestion}
                description={questions[questionNumber - 1].description}/>}
        </div>
    )
}

const FakePopup = ({i18n, date, description, link, originalVideo, nextQuestion}) => (
    <div className="status-popup fake-status">
        <div className="popup-animate-element animate-top">
            <img src="/images/deep-fake/error.svg" alt=""/>
            <div>{i18n.t("common:FakeVideo")}</div>
        </div>
        <div className="popup-animate-element animate-bottom video-info">
            <span>{i18n.t("common:VideoOriginal")}</span>
            <video controls="controls" autoplay className="video-player">
                <source src={originalVideo} type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' />
            </video>
            <p>{description}</p>
            <p>{date}</p>
            <p><a href="{link}" target="_blank">{link}</a></p>
        </div>
        <div className="btn-wrapper">
            <div
                className="game-btn next-btn hover-scale"
                onClick={nextQuestion}
            >
                {i18n.t("common:Buttons.next")}
            </div>
        </div>
    </div>
)

const ReallyPopup = ({i18n, date, description, link, nextQuestion}) => (
    <div className="status-popup really-status">
        <div className="popup-animate-element animate-top">
            <img src="/images/deep-fake/check.svg" alt=""/>
            <div>{i18n.t("common:RealVideo")}</div>
        </div>
        <div className="popup-animate-element animate-bottom video-info">
            <p>{description}</p>
            <p>{date}</p>
            <p><a href="{link}" target="_blank">{link}</a></p>
        </div>
        <div className="btn-wrapper">
            <div
                className="game-btn next-btn hover-scale"
                onClick={nextQuestion}
            >
                {i18n.t("common:Buttons.next")}
            </div>
        </div>
    </div>
)