import React, {useContext, useEffect} from "react"
import {Link} from "react-router-dom";
import {LangContext} from "../../App";
import {ExponatsLangPicker} from "../ExponatsLangPicker";

export const Exponat = () => {
    const i18n = useContext(LangContext)
    useEffect(() => {
        document.body.classList.add("body-home-page")
        return () => {
            document.body.classList.remove("body-home-page")
        }
    },[])
    return(
        <div className="exponat-page">
            <div className="exponat-page-wrapper">
                <div className="back-arrow nav-panel">
                    <Link to="/"><img src="/images/game1/arrow-back.svg" alt="" className="hover-scale"/></Link>
                    <ExponatsLangPicker />
                </div>
                <div className="exponat-page__title">{i18n.t('Title.Forgotpass')}</div>
                <p className="author">Художник: Арам Бартхолл</p>
                <div className="video-wrapper">
                    <iframe src="https://player.vimeo.com/video/463816618" width="640" height="360" frameBorder="0"
                            allow="autoplay; fullscreen" allowFullScreen></iframe>
                </div>
                <p>{i18n.t('Description.Forgotpass')}</p>
            </div>
        </div>
    )
}