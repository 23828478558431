import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { LangContext } from "../../App";


export const UaReview = () => {
    const i18n = useContext(LangContext)
    useEffect(() => {
        document.body.classList.add("body-home-page")
        return () => {
            document.body.classList.remove("body-home-page")
        }
    }, [])
    return (
        <div className="exponat-page">
            <div className="exponat-page-wrapper">
                <div className="back-arrow nav-panel">
                    <Link to="/"><img src="/images/game1/arrow-back.svg" alt="" className="hover-scale" /></Link>
                    {/* <ExponatsLangPicker /> */}
                </div>
                <div className="exponat-page__title">{i18n.t('Title.UaReview')}</div>
                <p className="author">
                    {i18n.t('Text')}: {i18n.t('Author.UaReview.text')}
                    <br />
                    {i18n.t('Design')}: {i18n.t('Author.UaReview.design')}
                </p>
                <p className="default-description">
                    {i18n.t('Description.UaReview.1')}<br /><br />
                    {i18n.t('Description.UaReview.2')}
                </p>
                <div className="pdf-preview-wrapper">
                    <a href={i18n.t('PDF.UaReview')} target="_blank"><img src={i18n.t('Preview.UaReview')} alt="" className="pdf-preview" /></a>
                </div>
            </div>
        </div >
    )
}