import React, {useState} from "react"
import {Screen_13} from "./Screen_13"

export const Screen_12 = ({changeScreen, i18n}) => {
    const [answer, setAnswer] = useState(false)
    const [animation, setAnimation] = useState(false)

    const checkAnswerHandler = () => {
        setAnswer(true)
        setTimeout(() => {
            setAnimation(true)
        }, 300)
    }
    return(
        <div className={animation ? "waldo-game hidden-overflow" : "waldo-game"}>
            <div className="back-arrow">
                <a href="/find-waldo"><img src="/images/game1/arrow-back.svg" alt="" className="hover-scale"/></a>
                <div className="innovative-partner">
                    <span>{i18n.t("common:Innovativepartner")}</span>
                    <img src="/images/find-waldo/lenovologo-pos-black.png" alt=""/>
                </div>
            </div>
            <div className="waldo-game__default-block default-block-wrapper">
                <div
                    className={animation ? "waldo-game__default-block gradient-type-2 a-b-question animate"
                        : "waldo-game__default-block gradient-type-2 a-b-question"}>
                    <div className="a-b-question-block">
                        <div className="a-b-question-block__head">{i18n.t("waldogame:Screen12.title")}</div>
                        <div className="a-b-question-block__body">
                            <div
                                onClick={checkAnswerHandler}
                                className={answer ? "question a-question right-answer answered" : "question a-question right-answer"}>
                                {answer ? <div className="check-circle "></div>
                                    : <div>a</div> }
                                {i18n.t("waldogame:Screen12.text1")}</div>
                            <div
                                onClick={checkAnswerHandler}
                                className={answer ? "question b-question error-answer answered" : "question b-question error-answer"}>
                                {answer ? <div className="error-circle"></div>
                                    : <div>b</div>}
                                {i18n.t("waldogame:Screen12.text2")}</div>
                        </div>
                    </div>
                </div>
                {animation && <Screen_13 changeScreen={changeScreen} i18n={i18n} />}
            </div>
        </div>
    )
}