import React from "react"
import {Link} from "react-router-dom"
import {LangPicker} from "../../LangPicker";

export const StartGame = ({changeScreen, i18n}) => (
    <div className="game-container take-quiz-game">
        <div className="back-arrow nav-panel">
            <Link to="/"><img src="/images/game1/arrow-back.svg" alt="" className="hover-scale"/></Link>
            <LangPicker/>
        </div>
        <div className="container-content">
            <div className="container-content-wrapper">
                <div className="animate-center-box">
                    <p className="text-margin">Take a moment, take a quiz</p>
                    <div className="container-title small-screen">
                        <img src="/images/take-quiz/tunnel.gif" alt="" className="tunnel-gif"/>
                        <div className="text-frame frame-1">{i18n.t("quizgame:StartText.1")}<br/>{i18n.t("quizgame:StartText.2")},</div>
                        <div className="text-frame frame-2">{i18n.t("quizgame:StartText.3")}<br/>{i18n.t("quizgame:StartText.4")},</div>
                        <div className="text-frame frame-3 green-text">{i18n.t("quizgame:StartText.5")}<br/>{i18n.t("quizgame:StartText.6")}</div>
                    </div>
                </div>
            </div>
        </div>
        <div className="btn-wrapper">
            <div
                className="game-btn start-btn hover-scale"
                onClick={() => changeScreen('FbScreen')}
            >
                {i18n.t("common:Buttons.start")}
            </div>
        </div>
    </div>
)