import React from "react"
import Slider from "react-slick";

export const PolygonScreen = ({changeScreen, sliders, slider, polygonText, setSlider}) => {
    const nextSlider = () => {
        if(slider + 1 == sliders.length) {
            changeScreen("EndScreen")
            return false
        }
        setSlider(prevSlider => prevSlider + 1)
        changeScreen("SliderScreen")
    }
    return(
        <div className="game-container take-quiz-slider-container">
            <div className="back-arrow">
                <a href="/"><img src="/images/game1/arrow-back.svg" alt="" className="hover-scale"/></a>
            </div>
            <div className="container-content">
                <div className="container-content-wrapper">
                    <div className="polygon-box" onClick={nextSlider}>
                        <img src="/images/take-quiz/snowflake.svg" alt="" className="snowflake snowflake-top" />
                        <img src="/images/take-quiz/polygon.svg" alt="" className="polygon-img"/>
                        <p className="">{polygonText}</p>
                        <img src="/images/take-quiz/snowflake.svg" alt="" className="snowflake snowflake-bottom"/>
                    </div>
                </div>
            </div>
        </div>
    )
}