import React, {useContext} from "react"
import {Link} from "react-router-dom"
import {LangPicker} from "../../LangPicker";

export const StartGame = ({changeScreen, i18n}) => {
    return(
        <div className="game-container">
            <div className="back-arrow nav-panel">
                <Link to="/"><img src="/images/game1/arrow-back.svg" alt="" className="hover-scale"/></Link>
                <LangPicker/>
            </div>
            <div className="container-content">
                <div className="container-content-wrapper">
                    <div className="container-title">AI VERSUS</div>
                    <div className="game-autor">{i18n.t('AuthorText')}: ISD Group</div>
                    <p>{i18n.t('common:Description.aiversus')}</p>
                </div>
            </div>
            <div className="btn-wrapper">
                <div
                    className="game-btn start-btn hover-scale"
                    onClick={() => changeScreen('GameField')}
                >
                    {i18n.t("common:Buttons.start")}
                </div>
            </div>
        </div>
    )
}