import React, {useEffect, useRef} from "react"
import {Link} from "react-router-dom"

export const GameField = (
    {
        changeScreen,
        startTimer,
        startAnimation,
        animation,
        data,
        score,
        twitt,
        timer,
        i18n
    }) => {


    useEffect(() => {
        startTimer()
    }, [])

    return(
        <div className="game-container twitt-container">
            <div className="back-arrow nav-panel">
                <Link to="/"><img src="/images/game1/arrow-back.svg" alt="" className="hover-scale"/></Link>
                <div className="timer-bar" id="timerBar"></div>
                <div className="timer pixel-font">{timer}</div>
            </div>
            <div className={"container-content twitt-position-" + twitt}>
                <div className={animation ? "twitt-box animating" : "twitt-box"} onClick={startAnimation}>
                    <div className="twitt-icon-wrapper">
                        <img src="/images/hold-breath/bird.svg" alt="" className="twitt-icon"/>
                    </div>
                    <div className="border">
                        <div className="border-text">{i18n.t("holdbreath:"+(score + 1))}</div>
                    </div>
                    {animation && <img src="/images/hold-breath/fire.gif" className="fire-gif" />}
                </div>
            </div>
        </div>
    )
}