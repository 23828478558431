import React, {useState} from "react"

export const Screen_5 = ({changeScreen, i18n}) => {
    const [animation, setAnimation] = useState(false)

    const checkZipHandler = () => {
        setAnimation(true)
        setTimeout(() => {
            changeScreen("Screen_6")
        }, 1200)
    }

    return(
        <div className="waldo-game">
            <div className="back-arrow">
                <a href="/find-waldo"><img src="/images/game1/arrow-back.svg" alt="" className="hover-scale"/></a>
                <div className="innovative-partner">
                    <span>{i18n.t("common:Innovativepartner")}</span>
                    <img src="/images/find-waldo/lenovologo-pos-black.png" alt=""/>
                </div>
            </div>
            <div className="waldo-game__default-block background-1">
                <div className="like-zip">
                    <p>{i18n.t("waldogame:Screen5.text1")}</p>
                    <div className={animation ? "animate scale" : "scale"} onMouseDown={checkZipHandler} onTouchStart={checkZipHandler}>
                        <img src="/images/find-waldo/smile.svg" alt=""/>
                    </div>
                </div>
            </div>
        </div>
    )
}
