import React, {useRef} from "react"
import {Keyboard} from "../Keyboard"
import {AccessDenied} from "./AccessDenied";
import {AllowedSuccess} from "./AllowedSuccess";


const Answer = ({password, nextTask, passType,answer, prompt, i18n}) => (
    <div className="phone-block">
        <img src="/images/passwords/phone-bg.svg" alt=""/>
        <div className="phone-block__content">
            <div className="input-pass-title pixel-font">{i18n.t("passgame:InputPass")}</div>
            <input type="text" readOnly="readOnly" autoFocus="autoFocus" value={password} className="pass-game-input success"/>
            <span className="answer-prompt">{prompt}</span>
            <div className={prompt ? "phone-block__content-text answer-text prompt-isset" : "phone-block__content-text answer-text"}>
                <span style={{fontFamily:'Circe-Bold', display:"block", color:"#000",marginBottom:"5px",fontSize:"14px"}}>{passType}</span>
                <p>{answer}</p>
            </div>
            <div className="btn-wrapper absolut-btn-wrapper password-game-btn-wrapper bottom">
                <div
                    onClick={nextTask}
                    className="game-btn start-btn password-game-btn success hover-scale"
                >{i18n.t("common:Buttons.nextTask")}</div>
            </div>
        </div>
    </div>
)


export const Pass_1 = (
    {
        i18n,
        task,
        data,
        success,
        denied,
        answer,
        attempt,
        nextTask,
        setAttempt,
        setSuccess,
        setDenied,
        setAnswer,
        audioError,
        audioSuccess
    }) => {
    const inputRef = useRef(null);

    const onKeyboardClickHandler = (e) => {
        e.preventDefault()

        const password = data[task - 1].password
        const passLength = +data[task - 1].passLength
        const inputValue = inputRef.current.value

        if(inputValue.length < passLength) {
            inputRef.current.value = inputRef.current.value + e.target.getAttribute('data-number')
        }
        if(inputValue.length + 1 === passLength) {
            if(inputRef.current.value == password) {
                setTimeout(() => {
                    setSuccess(true)
                }, 500)
            }else {
                setTimeout(() => {
                    setDenied(true)
                    setAttempt(attemptPrev => attemptPrev - 1)
                }, 500)
            }
        }
    }
    return(
        <div className="container-content">
            <div className="container-content-wrapper">
                {denied && <AccessDenied i18n={i18n} setDenied={setDenied} setAnswer={setAnswer} attempt={attempt} audioError={audioError} />}
                {success && <AllowedSuccess i18n={i18n} setAnswer={setAnswer} audioSuccess={audioSuccess}/>}
                {answer ?
                    <Answer
                        i18n={i18n}
                        password={data[task - 1].password}
                        nextTask={nextTask}
                        passType={i18n.t("passgame:"+task+".passType")}
                        answer={i18n.t("passgame:"+task+".answer")}
                        prompt={i18n.t("passgame:"+task+".prompt")} />
                :
                <div className={denied || success ? "phone-block hide-block" : "phone-block"}>
                    <img src="/images/passwords/phone-bg.svg" alt=""/>
                    <div className="phone-block__content">
                        <div className="input-pass-title">{i18n.t("passgame:InputPass")}</div>
                        <div className="input-wrapper">
                            <input
                                type="text"
                                ref={inputRef}
                                readOnly="readOnly"
                                autoFocus="autoFocus"
                                value={''}
                                maxLength={data[task - 1].passLength}
                                className={attempt < 5 ? "pass-game-input error" : "pass-game-input"}/>
                            {attempt < 5 && <span className="error-text">{i18n.t("passgame:AttemptLeft")}: {attempt}</span>}
                        </div>
                        <div className="phone-block__content-text with-keyboard">
                            <p>{i18n.t("passgame:"+task+".explanation")}</p>
                            <Keyboard btnPress={onKeyboardClickHandler} />
                        </div>
                    </div>
                </div>}
            </div>
        </div>
    )
}