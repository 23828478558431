import React, {useContext, useState} from "react"
import {Link} from "react-router-dom";
import {StartGame} from "./screens/StartGame";
import {Pass_1} from "./screens/Pass_1";
import "./style.scss"
import data from "./data.json"
import {EndGame} from "./screens/EndGame";
import {LangContext} from "../../App";

export const Passwords = () => {
    const [screen, setScreen] = useState('StartGame')
    const [success, setSuccess] = useState(false)
    const [denied, setDenied] = useState(false)
    const [answer, setAnswer] = useState(false)
    const [task, setTask] = useState(1)
    const [attempt, setAttempt] = useState(5)
    const [score, setScore] = useState(data.length)

    const changeScreen = (screen) => setScreen(screen)

    const i18n = useContext(LangContext)

    const nextTask = () => {
        if(data.length  == task) {
            changeScreen("EndGame")
            return false
        }
        if (attempt === 0) {
            setScore(prevScore => prevScore - 1)
        }
        setSuccess(false)
        setAnswer(false)
        setAttempt(5)
        setTask(prevTask => prevTask + 1)
    }

    const audioSuccess = new Audio('/ctrl/sounds/success.mp3');
    const audioError = new Audio('/ctrl/sounds/error.mp3');

    return(
        <div className="game-field game light-theme password-game">
            <div className="game-container">
                <div className="back-arrow nav-panel">
                    {screen === 'StartGame'
                        ? <Link to="/"><img src="/images/passwords/arrow-back-black.svg" alt="" className="hover-scale" /></Link>
                        : <a href="/passwords"><img src="/images/passwords/arrow-back-black.svg" alt="" className="hover-scale" /></a>}
                    <div className="question-numbering pixel-font">{task} / 7</div>
                </div>
                {screen === 'StartGame' && <StartGame changeScreen={changeScreen} i18n={i18n} />}
                {screen === 'Pass_1' &&
                    <Pass_1
                        i18n={i18n}
                        task={task}
                        attempt={attempt}
                        answer={answer}
                        success={success}
                        denied={denied}
                        data={data}
                        audioSuccess={audioSuccess}
                        audioError={audioError}
                        setTask={setTask}
                        nextTask={nextTask}
                        setAttempt={setAttempt}
                        setAnswer={setAnswer}
                        setDenied={setDenied}
                        setSuccess={setSuccess}
                        changeScreen={changeScreen}/>}
                {screen === 'EndGame' && <EndGame score={score} i18n={i18n} />}
            </div>
        </div>
    )
}