import React, {useEffect} from "react"


export const AccessDenied = ({setDenied, setAnswer, attempt, audioError, i18n}) => {
    useEffect(() => {
        audioError.play()
    },[])

    const tryAgain = () => {
        if(attempt > 0) {
            setDenied(false)
        }else {
            setDenied(false)
            setAnswer(true)
        }
    }

    return(
        <div className="phone-block denied-allowed-wrap access-denied-phone headShake">
            <img src="/images/passwords/denide-bg.svg" alt=""/>
            <div className="phone-block__content denied-allowed-wrap__content">
                <div className="denied-allowed-title">{i18n.t("passgame:Access")}<br/>{i18n.t("passgame:Denied")}</div>
                <div className="denied-allowed-attemptions">{i18n.t("passgame:AttemptLeft")}: {attempt}</div>
                <div className="btn-wrapper absolut-btn-wrapper password-game-btn-wrapper bottom">
                    <div
                        onClick={tryAgain}
                        className="game-btn start-btn password-game-btn light-btn hover-scale">
                        {attempt === 0 ? i18n.t("passgame:ShowAnswer") : i18n.t("passgame:TryAgain")}
                    </div>
                </div>
            </div>
        </div>
    )
}