import React from "react"
import {Link} from "react-router-dom";

export const StartGame = ({changeScreen, i18n}) => {
    return(
        <div className="container-content">
            <div className="container-content-wrapper">
                <div className="phone-block">
                    <img src="/images/passwords/phone-bg.svg" alt=""/>
                    <div className="phone-block__content">
                        <div className="input-pass-title">{i18n.t("passgame:InputPass")}</div>
                        <input type="text" readOnly="readOnly" autoFocus="autoFocus" value="12345" className="pass-game-input success"/>
                        <div className="phone-block__content-text">
                            <p>{i18n.t("common:Description.passwords.1")}</p>
                            <p>{i18n.t("common:Description.passwords.2")}</p>
                            <p className="bold-text">{i18n.t("common:Description.passwords.3")}</p>
                        </div>
                        <div className="btn-wrapper absolut-btn-wrapper password-game-btn-wrapper bottom">
                            <div
                                className="game-btn start-btn password-game-btn success hover-scale"
                                onClick={() => changeScreen('Pass_1')}>
                                {i18n.t("passgame:Buttons.start")}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}