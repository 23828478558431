import React from "react"
import {Link} from "react-router-dom"
import {LangPicker} from "../../LangPicker";

export const StartGame = ({changeScreen, i18n}) => (
    <div className="game-container">
        <div className="back-arrow nav-panel">
            <Link to="/"><img src="/images/game1/arrow-back.svg" alt="" className="hover-scale"/></Link>
            <LangPicker/>
        </div>
        <div className="container-content">
            <div className="container-content-wrapper">
                <div className="container-title">reCaptcha</div>
                <p>{i18n.t('common:Description.recaptcha')}</p>
            </div>
        </div>
        <div className="btn-wrapper">
            <div
                className="game-btn start-btn hover-scale"
                onClick={() => changeScreen('ChapterOne')}
            >
                {i18n.t('common:Buttons.notrobot')}
            </div>
        </div>
    </div>
)