import React, {useRef} from "react"
import { useTranslation } from "react-i18next"

export const LangPicker = () => {
    const { i18n } = useTranslation();
    const langSelect = useRef('')
    const ChangeLanguage = (lang) => {
        i18n.changeLanguage(lang);
    }
    return(
        <div className='global-lang-panel'>
            <select value={i18n.language} onChange={() => ChangeLanguage(langSelect.current.value)} ref={langSelect}>
                <option value="ua">Укр</option>
                <option value="en">En</option>
                <option value="ru">Pус</option>
                <option value="arm">Arm</option>
            </select>
        </div>
    )
}