import React, {useState} from "react"
import {NextWiget} from "../NextWiget";
import {Prompt} from "../Prompt";

export const Screen_10 = ({changeScreen, i18n}) => {

    const [prompt, setPrompt] = useState(false)
    const [link, setLink] = useState(false)


    return(
        <div className="waldo-game">
            {prompt && <Prompt setPrompt={setPrompt} text={i18n.t("waldogame:Screen10.prompt")}/>}
            <div className="back-arrow">
                <a href="/find-waldo"><img src="/images/game1/arrow-back.svg" alt="" className="hover-scale"/></a>
                <div className="innovative-partner">
                    <span>{i18n.t("common:Innovativepartner")}</span>
                    <img src="/images/find-waldo/lenovologo-pos-black.png" alt=""/>
                </div>
            </div>
            <div className="waldo-game__default-block no-padding scroll-y">
                <img src="/images/find-waldo/bg_2.jpg" alt="" className="bg-img"/>
                {link && <div className="picture-title" style={{top: '75%', left:'40%',transform:'translateX(-50%)'}} onClick={() => changeScreen("Screen_11")}>mike_white_beard</div>}
                <div className="next-wiget" onClick={() => {setPrompt(true); setLink(true)}}>
                    <img src="/images/find-waldo/next-arr.svg" alt=""/>
                </div>
            </div>
        </div>
    )
}