import React, {useContext, useState} from "react"
import {StartGame} from "./screens/StartGame";
import {GameField} from "./screens/GameField";
import {EndGame} from "./screens/EndGame";
import data from './data.json'
import "./style.scss"
import {LangContext} from "../../App";



export const AIVersus = () => {
    const [screen, setScreen] = useState('StartGame')
    const [success, setSuccess] = useState({rus: false, rain: false, points: 0})
    const [questionNumber, setQuestionNumber] = useState(1)

    const changeScreen = (screen) => setScreen(screen)
    const changeQuestionNumber = (number) => setQuestionNumber(number)

    const i18n = useContext(LangContext)

    return(
        <div className="game-field game">
            {screen === 'StartGame' && <StartGame changeScreen={changeScreen} i18n={i18n} />}
            {screen === 'GameField' &&
                <GameField
                    i18n={i18n}
                    success={success}
                    setSuccess={setSuccess}
                    questions={data}
                    questionNumber={questionNumber}
                    changeScreen={changeScreen}
                    changeQuestionNumber={changeQuestionNumber}/>}
            {screen === 'EndGame' && <EndGame points={success.points} i18n={i18n} />}
        </div>
    )
}