import React from "react"
import {LangPicker} from "../../LangPicker";
import {Link} from "react-router-dom";

export const StartGame = ({changeScreen, i18n}) => (
    <div className="game-container">
        <div className="back-arrow nav-panel">
            <Link to="/"><img src="/images/game1/arrow-back.svg" alt="" className="hover-scale"/></Link>
            <LangPicker/>
        </div>
        <div className="container-content">
            <div className="container-content-wrapper">
                <div className="container-title">News Tinder</div>
                <p>{i18n.t("common:Description.newstinder")}</p>
            </div>
        </div>
        <div className="btn-wrapper">
            <div
                className="game-btn start-btn hover-scale"
                onClick={() => changeScreen('QuestionsScreen')}
            >
                {i18n.t("common:Buttons.onstart")}
            </div>
        </div>
    </div>
)