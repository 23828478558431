import React, {useState} from "react"
import {Link} from "react-router-dom"


const ChapterOnePractice = ({ animate, inputValue, checkCaptchaCode, onInputChangeHandler, captchaCode, i18n }) => {
    const check = () => {
        if (window.innerWidth <= 1300) {
            checkCaptchaCode(captchaCode, 'ChapterTwo')
        }
    }
    return (
        <div className="input-captcha-practice">
            <img src="/images/recaptcha/captcha-code1.svg" alt="" />
            <input type="text" onBlur={check} onChange={onInputChangeHandler} value={inputValue} placeholder={i18n.t("common:Entercode")} className={animate && "animate"} />
            <div className="game-btn light-btn hover-scale" onClick={() => checkCaptchaCode(captchaCode, 'ChapterTwo')}>{i18n.t("common:Buttons.confirm")}</div>
        </div>
    )
}

const ChapterTwoPractice = ({ animate, inputValue, checkCaptchaCode, onInputChangeHandler, captchaCode, i18n }) => {
    const check = () => {
        if (window.innerWidth <= 1300) {
            checkCaptchaCode(captchaCode, 'ChapterThree')
        }
    }
    return (
        <div className="input-captcha-practice">
            <img src="/images/recaptcha/captcha-code2.svg" alt="" />
            <input type="text" onBlur={check} onChange={onInputChangeHandler} value={inputValue} placeholder={i18n.t("common:Entercode")} className={animate && "animate"} />
            <div className="game-btn light-btn hover-scale" onClick={() => checkCaptchaCode(captchaCode, 'ChapterThree')}>{i18n.t("common:Buttons.confirm")}</div>
        </div>
    )
}

const ChapterThreePractice = ({solved, checkReCaptcha,solveReCaptcha, setSolved, i18n}) => (
    <div className="matrix-recaptcha-block">
        <div className="title">{i18n.t("recaptcha:Practice.title1")}<br/><span>{i18n.t("recaptcha:Practice.font30_1")}</span></div>
        <div className='picture-matrix' onClick={(e) => solveReCaptcha(e, ['2', '6'])}>
            <div className="row">
                <div index="1"><img src="/images/recaptcha/group_1/image_part_001.png" alt=""/></div>
                <div index="2" onClick={() => setSolved([true, solved[1]])}><img src="/images/recaptcha/group_1/image_part_002.png" alt=""/></div>
                <div index="3"><img src="/images/recaptcha/group_1/image_part_003.png" alt=""/></div>
                <div index="4"><img src="/images/recaptcha/group_1/image_part_004.png" alt=""/></div>
            </div>
            <div className="row">
                <div index="5"><img src="/images/recaptcha/group_1/image_part_005.png" alt=""/></div>
                <div index="6" onClick={() => setSolved([solved[0], true])}><img src="/images/recaptcha/group_1/image_part_006.png" alt=""/></div>
                <div index="7"><img src="/images/recaptcha/group_1/image_part_007.png" alt=""/></div>
                <div index="8"><img src="/images/recaptcha/group_1/image_part_008.png" alt=""/></div>
            </div>
            <div className="row">
                <div index="9"><img src="/images/recaptcha/group_1/image_part_009.png" alt=""/></div>
                <div index="10"><img src="/images/recaptcha/group_1/image_part_010.png" alt=""/></div>
                <div index="11"><img src="/images/recaptcha/group_1/image_part_011.png" alt=""/></div>
                <div index="12"><img src="/images/recaptcha/group_1/image_part_012.png" alt=""/></div>
            </div>
            <div className="row">
                <div index="13"><img src="/images/recaptcha/group_1/image_part_013.png" alt=""/></div>
                <div index="14"><img src="/images/recaptcha/group_1/image_part_014.png" alt=""/></div>
                <div index="15"><img src="/images/recaptcha/group_1/image_part_015.png" alt=""/></div>
                <div index="16"><img src="/images/recaptcha/group_1/image_part_016.png" alt=""/></div>
            </div>
        </div>
        <div className="game-btn light-btn hover-scale"
             onClick={() => checkReCaptcha('ChapterFour')}
        >
            {i18n.t("common:Buttons.confirm")}
        </div>
    </div>
)

const ChapterFourPractice = ({solved, checkReCaptcha, solveReCaptcha, setSolved, i18n}) => (
    <div className="matrix-recaptcha-block">
        <div className="title">
            {i18n.t("recaptcha:Practice.title2")}
            <br/>
            {i18n.t("recaptcha:Practice.title3")}
            <span> {i18n.t("recaptcha:Practice.font30_2")}</span>
            <br/>
            {i18n.t("recaptcha:Practice.title4")}</div>
        <div className='picture-matrix' onClick={(e) => solveReCaptcha(e, ['7', '11'])}>
            <div className="row">
                <div index="1"><img src="/images/recaptcha/group_2/image_part_001.png" alt=""/></div>
                <div index="2"><img src="/images/recaptcha/group_2/image_part_002.png" alt=""/></div>
                <div index="3"><img src="/images/recaptcha/group_2/image_part_003.png" alt=""/></div>
                <div index="4"><img src="/images/recaptcha/group_2/image_part_004.png" alt=""/></div>
            </div>
            <div className="row">
                <div index="5"><img src="/images/recaptcha/group_2/image_part_005.png" alt=""/></div>
                <div index="6"><img src="/images/recaptcha/group_2/image_part_006.png" alt=""/></div>
                <div index="7" onClick={() => setSolved([true, solved[1]])}><img src="/images/recaptcha/group_2/image_part_007.png" alt=""/></div>
                <div index="8"><img src="/images/recaptcha/group_2/image_part_008.png" alt=""/></div>
            </div>
            <div className="row">
                <div index="9"><img src="/images/recaptcha/group_2/image_part_009.png" alt=""/></div>
                <div index="10"><img src="/images/recaptcha/group_2/image_part_010.png" alt=""/></div>
                <div index="11" onClick={() => setSolved([solved[0], true])}><img src="/images/recaptcha/group_2/image_part_011.png" alt=""/></div>
                <div index="12"><img src="/images/recaptcha/group_2/image_part_012.png" alt=""/></div>
            </div>
            <div className="row">
                <div index="13"><img src="/images/recaptcha/group_2/image_part_013.png" alt=""/></div>
                <div index="14"><img src="/images/recaptcha/group_2/image_part_014.png" alt=""/></div>
                <div index="15"><img src="/images/recaptcha/group_2/image_part_015.png" alt=""/></div>
                <div index="16"><img src="/images/recaptcha/group_2/image_part_016.png" alt=""/></div>
            </div>
        </div>
        <div className="game-btn light-btn hover-scale"
             onClick={() => checkReCaptcha('ChapterFive')}
        >
            {i18n.t("common:Buttons.confirm")}
        </div>
    </div>
)

const FinalBuble = () => (
    <div className="final-recaptcha-block">
        <div className="final-wrapper">
            <img src="/images/recaptcha/check-mark.svg" alt="" className="check-mark"/>
            <img src="/images/recaptcha/final-buble.svg" alt="" className="final-bloc-picture"/>
        </div>
    </div>
)




export const CaptchaPracticeScreen = ({changeScreen, chapter, screenHalfDetecterRemove, i18n}) => {

    const captchaCode = 'NOroBOTS'
    const captchaCode2 = 'Hasta La Vista Baby'

    const [inputValue, setInputValue] = useState('')
    const [animate, setAnimate] = useState(false)
    const [solved, setSolved] = useState([false, false])

    const onInputChangeHandler = (e) => {
        setInputValue(e.target.value)
    }
    screenHalfDetecterRemove()
    const checkCaptchaCode = (code, chapter) => {
        if(inputValue === code) {
            changeScreen(chapter)
        }
        setAnimate(true)
        setTimeout(() =>{
            setAnimate(false)
        }, 500)
    }

    const checkReCaptcha = (chapter) => {
        if(solved[0] && solved[1]) {
            changeScreen(chapter)
        }else {
            alert('error')
        }
    }

    const solveReCaptcha = (e, arr) => {
        const parent = e.target.parentElement
        if(parent.getAttribute('index') === arr[0] || parent.getAttribute('index') === arr[1]) {
            parent.classList.add('ping')
        }else {
            parent.classList.add('wrong')
            setTimeout(() => {
                parent.classList.remove('wrong')
            }, 500)
        }
    }



    return(
        <div className='recaptcha-light-screen'>
            <div className={animate ? "game-container recaptcha-container animate" : "game-container recaptcha-container"}>
                <div className="back-arrow nav-panel">
                    <img src="/images/game1/arrow-back.svg" alt="" className="hover-scale" onClick={() => changeScreen('StartGame')}/>
                    <div className="pixel-font">{chapter} / 5</div>
                </div>
                <div
                    style={chapter === 3 || chapter === 4 ? {paddingTop:'0'} : {}}
                    className={chapter === 5 ? "container-content final-buble" : "container-content"}>
                    {chapter === 1 && <ChapterOnePractice
                        i18n={i18n}
                        animate={animate}
                        inputValue={inputValue}
                        captchaCode={captchaCode}
                        checkCaptchaCode={checkCaptchaCode}
                        onInputChangeHandler={onInputChangeHandler}/>}
                    {chapter === 2 && <ChapterTwoPractice
                        i18n={i18n}
                        animate={animate}
                        inputValue={inputValue}
                        captchaCode={captchaCode2}
                        checkCaptchaCode={checkCaptchaCode}
                        onInputChangeHandler={onInputChangeHandler}/>}
                    {chapter === 3 && <ChapterThreePractice
                        i18n={i18n}
                        solved={solved}
                        setSolved={setSolved}
                        solveReCaptcha={solveReCaptcha}
                        checkReCaptcha={checkReCaptcha} />}
                    {chapter === 4 && <ChapterFourPractice
                        i18n={i18n}
                        solved={solved}
                        setSolved={setSolved}
                        solveReCaptcha={solveReCaptcha}
                        checkReCaptcha={checkReCaptcha} />}
                    {chapter === 5 && <FinalBuble />}
                </div>
            </div>
        </div>
    )
}


