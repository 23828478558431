import React, {useEffect, useState} from "react"
import {Prompt} from "../Prompt";

export const Screen_9 = ({changeScreen, i18n}) => {

    const [prompt, setPrompt] = useState(true)

    const solveReCaptcha = (e, num) => {
        const element = e.target.parentElement
        if(element.getAttribute('index') === num) {
            changeScreen('Screen_10')
        }else {
            element.classList.add('wrong')
            setTimeout(() => {
                element.classList.remove('wrong')
            }, 500)
        }
    }
    return(
        <div className="waldo-game">
            {prompt && <Prompt setPrompt={setPrompt} text={i18n.t("waldogame:Screen9.prompt")}/>}
            <div className="back-arrow">
                <a href="/find-waldo"><img src="/images/game1/arrow-back.svg" alt="" className="hover-scale"/></a>
                <div className="innovative-partner">
                    <span>{i18n.t("common:Innovativepartner")}</span>
                    <img src="/images/find-waldo/lenovologo-pos-black.png" alt=""/>
                </div>
            </div>
            <div className="waldo-game__default-block instagram-hastag-list scroll-y">
                <div className="instagram-hastag-list__head">
                    <div>#mawsonpeak</div>
                </div>
                <div className="instagram-hastag-list__info">
                    <img src="/images/find-waldo/hastag-profile.png" alt=""/>
                    <div className="instagram-hastag-list__info-text">
                        <div>9 {i18n.t("waldogame:Screen9.text1")}</div>
                        <p>{i18n.t("waldogame:Screen9.text2")}<br/>{i18n.t("waldogame:Screen9.text3")}</p>
                    </div>
                </div>
                <div className="instagram-hastag-list__body">
                    <div className="body-title">{i18n.t("waldogame:Screen9.text4")}</div>
                    <div className="photo-collection" onClick={(e) => solveReCaptcha(e, '2')}>
                        <div index="1"><img src="/images/find-waldo/inst-list/1.png" alt="" className="wrong"/></div>
                        <div index="2"><img src="/images/find-waldo/inst-list/2.png" alt=""/></div>
                        <div index="3"><img src="/images/find-waldo/inst-list/3.png" alt=""/></div>
                        <div index="4"><img src="/images/find-waldo/inst-list/4.png" alt=""/></div>
                        <div index="5"><img src="/images/find-waldo/inst-list/5.png" alt=""/></div>
                        <div index="6"><img src="/images/find-waldo/inst-list/6.png" alt=""/></div>
                        <div index="7"><img src="/images/find-waldo/inst-list/7.png" alt=""/></div>
                        <div index="8"><img src="/images/find-waldo/inst-list/8.png" alt=""/></div>
                        <div index="9"><img src="/images/find-waldo/inst-list/9.png" alt=""/></div>
                    </div>
                </div>
            </div>
        </div>
    )
}