import React, {useEffect, useRef, useState} from "react"
import Slider from "react-slick";
import {Link} from "react-router-dom"

export const RedirectComponent = ({changeScreen, changeChapter}) => {
    useEffect(() => {
        changeChapter(4)
        changeScreen('CaptchaPracticeScreen')
    },[changeChapter, changeScreen])
    return(
        <div></div>
    )
}

export const ChapterFour = ({changeChapter, changeScreen, slide, screenHalfDetecter, slides, i18n}) => {
    const customSlider = useRef();
    screenHalfDetecter()

    const [lastSlide, setLastSlide] = useState(false)

    const settings = {
        dots: true,
        initialSlide: slide,
        arrows: false,
        infinite: false,
        speed: 200,
        slidesToShow: 1,
        slidesToScroll: 2,
        beforeChange: (current, next) => {
            if(next == slides) {
                setLastSlide(true)
            }
        }
    };

    return(
        <div className="chapter-slide-effect">
            <div className="slide-prev" onClick={() => customSlider.current.slickPrev()}></div>
            <div className="slide-next" onClick={() => customSlider.current.slickNext()}></div>
            <div className="game-container recaptcha-container">
                <div className="back-arrow nav-panel">
                    <img src="/images/game1/arrow-back.svg" alt="" className="hover-scale" onClick={() => changeScreen('StartGame')}/>
                    <div className="pixel-font">4 / 5</div>
                </div>
                <div className="container-content">
                    <div className='slider-wrapper'>
                        <div className="slider-container">
                            <div className="slider-bg"></div>
                            <Slider {...settings} ref={slider => (customSlider.current = slider)}>
                                <div className='recaptcha-slide'>
                                    <div className="slide-content">
                                        <div className='slide-title'>{i18n.t("recaptcha:4.title")}</div>
                                    </div>
                                </div>
                                <div className='recaptcha-slide'>
                                    <div className="slide-content glitch"></div>
                                </div>
                                <div className='recaptcha-slide'>
                                    <div className="slide-content">
                                        <div>{i18n.t("recaptcha:4.slide1")}</div>
                                    </div>
                                </div>
                                <div className='recaptcha-slide'>
                                    <div className="slide-content glitch"></div>
                                </div>
                                <div className='recaptcha-slide'>
                                    <div className="slide-content">
                                        <div>{i18n.t("recaptcha:4.slide2")}</div>
                                    </div>
                                </div>
                                <div className='recaptcha-slide'>
                                    <div className="slide-content glitch"></div>
                                </div>
                                <div className='recaptcha-slide'>
                                    <div className="slide-content">
                                        <div>{i18n.t("recaptcha:4.slide3")}</div>
                                    </div>
                                </div>
                                <div className='recaptcha-slide'>
                                    <div className="slide-content glitch"></div>
                                </div>
                                <div className='recaptcha-slide'>
                                    <div className="slide-content">
                                        <div>{i18n.t("recaptcha:4.slide4")}</div>
                                    </div>
                                </div>
                                <div className='recaptcha-slide'>
                                    <div className="slide-content"></div>
                                </div>
                                <div className='recaptcha-slide'>
                                    {lastSlide && <RedirectComponent
                                        changeScreen={changeScreen}
                                        changeChapter={changeChapter}/> }
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}