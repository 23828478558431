import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const HomePage = () => {
    useEffect(() => {
        document.body.classList.add("body-home-page")
        return () => {
            document.body.classList.remove("body-home-page")
        }
    }, [])
    return (
        <div className="home-page">
            <div className="preview-wrapper">
                <div className="main-content" style={{ paddingBottom: "60px" }}>
                    <a href="https://datactrlcentre.com/"><img src="/images/game1/preview-text.svg" alt="" /></a>
                    <div className="info-on-main">
                        <div>
                            <div>[1]</div>
                            <Link to="/wallpaper-exponat">Wallpaper Project</Link>
                        </div>
                        <div>
                            <div>[2]</div>
                            <Link to="/hooked">Hooked</Link>
                        </div>
                        <div>
                            <div>[3]</div>
                            <Link to="/are-you-hooked">Are you hooked?</Link>
                        </div>
                        <div>
                            <div>[4]</div>
                            <Link to="/room-with-followers">Room with followers</Link>
                        </div>
                        <div>
                            <div>[5]</div>
                            <Link to="/the-real-life-selfie">The real life of your selfie</Link>
                        </div>
                        <div>
                            <div>[6]</div>
                            <Link to="/double-check">Double check</Link>
                        </div>
                        <div>
                            <div>[7]</div>
                            <Link to="/deep-future">Deep future</Link>
                        </div>
                        <div>
                            <div>[8]</div>
                            <Link to="/grab-your-attention">How your phone is designed to grab your attention</Link>
                        </div>
                        <div>
                            <div>[9]</div>
                            <Link to="/fact-checking">Fact-checking relaxations</Link>
                        </div>
                        <div>
                            <div>[10]</div>
                            <Link to="/facebook-report">Facebook report</Link>
                        </div>
                        <div>
                            <div>[11]</div>
                            <Link to="/fake-or-real">Fake or Real - News Edition</Link>
                        </div>
                        <div>
                            <div>[12]</div>
                            <Link to="/user-agreement-bed-night-stories">User agreement bed night stories</Link>
                        </div>
                        <div>
                            <div>[13]</div>
                            <Link to="/deep-fake-lab">Deep fake lab</Link>
                        </div>
                        <div>
                            <div>[14]</div>
                            <Link to="/behind-the-screen">Behind the screen</Link>
                        </div>
                        <div>
                            <div>[15]</div>
                            <Link to="/take-a-quiz">Take a moment, take a quiz</Link>
                        </div>
                        <div>
                            <div>[16]</div>
                            <Link to="/cyberstickers">Cyberstickers</Link>
                        </div>
                        <div>
                            <div>[17]</div>
                            <Link to="/passwords">Unlock my phone</Link>
                        </div>
                        <div>
                            <div>[18]</div>
                            <Link to="/vpn-countries">VPN countries map</Link>
                        </div>
                        <div>
                            <div>[19]</div>
                            <Link to="/get-bad-news">Get bad news</Link>
                        </div>
                        <div>
                            <div>[20]</div>
                            <Link to="/aiversus">AI versus</Link>
                        </div>
                        <div>
                            <div>[21]</div>
                            <Link to="/original-or-not">Original or not?</Link>
                        </div>
                        <div>
                            <div>[22]</div>
                            <Link to="/find-waldo">Find Waldo</Link>
                        </div>
                        <div>
                            <div>[23]</div>
                            <Link to="/hold-breath">Hold your breath (COVID-19)</Link>
                        </div>
                        <div>
                            <div>[24]</div>
                            <Link to="/recaptcha">ReCaptcha</Link>
                        </div>
                        <div>
                            <div>[25]</div>
                            <Link to="/news-tinder">News Tinder</Link>
                        </div>
                        <div>
                            <div>[26]</div>
                            <Link to="/data-detox-kit">Data detox kit</Link>
                        </div>
                        <div>
                            <div>[27]</div>
                            <Link to="/drop-in-the-ocean">Drop in the OCEAN</Link>
                        </div>
                        <div>
                            <div>[28]</div>
                            <Link to="/facebook-heritage">Facebook Heritage</Link>
                        </div>
                        <div>
                            <div>[29]</div>
                            <Link to="/not-every-malware-medoc">Not every malware is M.E.Doc</Link>
                        </div>
                        <div>
                            <div>[30]</div>
                            <Link to="/fishing">Fishing</Link>
                        </div>
                        <div>
                            <div>[31]</div>
                            <Link to="/cybersecurity-for-kids">Cybersecurity for kids</Link>
                        </div>
                        <div>
                            <div>[32]</div>
                            <Link to="/step-only-black-spots">Step only black spots</Link>
                        </div>
                        <div>
                            <div>[33]</div>
                            <Link to="/how-brain-reacts-to-misinformation">How our brain reacts to misinformation?</Link>
                        </div>
                        <div style={{ borderBottom: "none" }}>
                            <div>[34]</div>
                            <Link to="/ua-review">UA Review: satirical and fake news</Link>
                        </div>
                        <div style={{ borderBottom: "none" }}>
                            <div>[35]</div>
                            <Link to="/voice-of-common-sense">The voice of common sense</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomePage