import React from "react"
import {Link} from "react-router-dom";

export const EndScreen = ({ i18n, changeScreen}) => {
    return(
        <div className="game-container take-quiz-game end-screen">
            <div className="back-arrow">
                <Link to="/"><img src="/images/game1/arrow-back.svg" alt="" className="hover-scale"/></Link>
            </div>
            <div className="container-content">
                <div className="container-content-wrapper full-height-wrapper">
                    <div className="animate-center-box">
                        <div className="container-title small-screen">
                            <div className="text-frame frame-1">{i18n.t("quizgame:EndText.1")}<br/>{i18n.t("quizgame:EndText.2")}<br/>{i18n.t("quizgame:EndText.3")},</div>
                            <div className="text-frame frame-2 green-text">{i18n.t("quizgame:EndText.4")}<br/>{i18n.t("quizgame:EndText.5")}</div>
                            <img src="/images/take-quiz/eye.gif" alt="" className="eye-gif" />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}