
import React, {useContext, useEffect} from "react"
import {Link} from "react-router-dom"
import {LangContext} from "../../App";
import {ExponatsLangPicker} from "../ExponatsLangPicker";
import Helmet from "react-helmet";


export const RoomWithFollowers = () => {
    const i18n = useContext(LangContext)
    useEffect(() => {
        document.body.classList.add("body-home-page")
        return () => {
            document.body.classList.remove("body-home-page")
        }
    },[])
    return(
        <div className="exponat-page">
            <Helmet>
                <meta name="keywords" content="HTML,CSS,JavaScript" />
            </Helmet>
            <div className="exponat-page-wrapper">
                <div className="back-arrow nav-panel">
                    <Link to="/"><img src="/images/game1/arrow-back.svg" alt="" className="hover-scale"/></Link>
                    <ExponatsLangPicker />
                </div>
                <div className="exponat-page__title">{i18n.t('Title.RoomWithFollowers')}</div>
                <p className="author">{i18n.t('Design')}: {i18n.t('Author.RoomWithFollowers.1')}</p>
                <p className="author">{i18n.t('Design')}: {i18n.t('Author.RoomWithFollowers.2')}</p>
                <p className="default-description">{i18n.t('Description.RoomWithFollowers')}</p>
                <div className="qr-code-block-wrapper">
                    <div className="qr-code-block">
                        <p>{i18n.t("Goto")} Facebook:</p>
                        <a href="https://www.facebook.com/fbcameraeffects/tryit/349153763036736/"><img src="/images/exponats/room-followers/qr-fb.png" alt=""/></a>
                    </div>
                    <div className="qr-code-block">
                        <p>{i18n.t("Goto")} Instagram:</p>
                        <a href="https://www.instagram.com/ar/714253545872726"><img src="/images/exponats/room-followers/qr-inst.png" alt=""/></a>
                    </div>
                </div>
            </div>
        </div>
    )
}
