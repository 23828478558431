import React, {useEffect, useRef, useState} from "react"
import {Link} from "react-router-dom";
import { DragDropContainer, DropTarget } from 'react-drag-drop-container'
import data from "../data.json";

export const GameField = ({questions, success, setSuccess, changeScreen, questionNumber, changeQuestionNumber, i18n}) => {
    const answerBlock = useRef('');
    const [boxChecked, setBoxChecked] = useState({rus: false, rain: false})
    const [check, setCheck] = useState('')
    const [nextBtn, setNextBtn] = useState(false)

    useEffect(() => {
        questionRandom()
    }, [])

    const createNewBox = (e, tv) => {
        const coords = getCoords(e.target)
        const box = document.createElement("div")
        box.className = 'drag-box absolute ghost ' + tv
        box.style.cssText = "position: absolute;transition:2s;top:"+e.dragElem.style.top+";left:"+e.dragElem.style.left+";"
        box.innerHTML = '<img src="/images/aiversus/drag.gif" alt=""/>'
        document.body.appendChild(box)
        box.style.top = (coords.top - 1)+'px'
        box.style.left = (coords.left - 1)+'px'
    }

    const onDropTargetHandler = (e, tv) => {
        createNewBox(e, e.dragData.tvName)
        setBoxChecked({...boxChecked, [e.dragData.tvName]:true})
        if (tv == e.dragData.tvName) {
            setSuccess({...success, [tv]:true})
        }
    }

    const questionRandom = () => {
        const arr = ['column','column-reverse']
        let randomDirection = arr[Math.floor(Math.random() * arr.length)];
        answerBlock.current.classList.add(randomDirection)
    }

    const getCoords = (elem) => { // кроме IE8-
        var box = elem.getBoundingClientRect()
        return {
            top: box.top,
            left: box.left
        }
    }

    const checkAnswersHandler = () => {
        setNextBtn(true)
        if(success.rain && success.rus) {
            setCheck('success')
            setSuccess({...success, points: success.points + 1})
            return false
        }
        setCheck('error')
    }

    const nextQuestion = (number) => {
        if(number <= data.length) {
            changeQuestionNumber(number)
        }else {
            changeScreen('EndGame')
        }
        reloadGameState()
    }

    const reloadGameState = () => {
        setCheck('')
        setNextBtn(false)
        setBoxChecked({rus: false, rain: false})
        setSuccess({...success, rus: false, rain: false})
        const ghostBoxes = document.querySelectorAll('.ghost')
        for(let i=0;i < ghostBoxes.length;i++) {
            ghostBoxes[i].remove()
        }
    }

    return(
        <div className="game-container aiversus-container">
            <div className="back-arrow nav-panel">
                <Link to="/"><img src="/images/game1/arrow-back.svg" alt="" className="hover-scale"/></Link>
                <div className="question-numbering pixel-font">{questionNumber} / {questions.length}</div>
                <div className="points">
                    <img src="/images/aiversus/menu-check.svg" alt=""/>
                    <span className="pixel-font">{success.points}</span>
                </div>
            </div>
            <div className="container-content">
                <div className="container-content-wrapper">
                    <div className="container-title question-title">{i18n.t('versusData:'+questionNumber+'.question')}</div>
                    <div className="answer-block" ref={answerBlock}>
                        <div className="answer-block__row">
                            <DropTarget targetKey="box" onHit={(e) => onDropTargetHandler(e, 'rus')}>
                                <div className={"answer-cell " + check}>
                                    {check !== 'error' ?
                                        <img src="/images/aiversus/screen-drag.svg" alt=""/>
                                        :
                                        <img src="/images/aiversus/glitch.gif" alt="" className="error-glitch"/>
                                    }
                                </div>
                            </DropTarget>
                            <div className="answer-text">{i18n.t('versusData:'+questionNumber+'.rus')}</div>
                        </div>
                        <div className="answer-block__row">
                            <DropTarget targetKey="box" onHit={(e) => onDropTargetHandler(e, 'rain')} >
                                <div className={"answer-cell " + check}>
                                    {check !== 'error' ?
                                        <img src="/images/aiversus/screen-drag.svg" alt=""/>
                                        :
                                        <img src="/images/aiversus/glitch.gif" alt="" className="error-glitch"/>
                                    }
                                </div>
                            </DropTarget>
                            <div className="answer-text">{i18n.t('versusData:'+questionNumber+'.rain')}</div>
                        </div>
                    </div>
                    <div className="variant-drag-boxes">
                        <div className={boxChecked.rus && boxChecked.rain || nextBtn ? "drag-box-wrapper absolute" : "drag-box-wrapper"}>
                            {!boxChecked.rus &&
                                <DragDropContainer
                                    targetKey="box"
                                    dragData={{tvName:'rus'}}>
                                    <div className="drag-box rus">
                                        <img src="/images/aiversus/drag.gif" alt=""/>
                                    </div>
                                </DragDropContainer>
                            }
                        </div>
                        <div className={boxChecked.rus && boxChecked.rain || nextBtn ? "drag-box-wrapper absolute" : "drag-box-wrapper"}>
                            {!boxChecked.rain &&
                                <DragDropContainer
                                    targetKey="box"
                                    dragData={{tvName: 'rain'}}>
                                    <div className="drag-box rain">
                                        <img src="/images/aiversus/drag.gif" alt=""/>
                                    </div>
                                </DragDropContainer>
                            }
                        </div>
                    </div>
                </div>
            </div>
            { boxChecked.rus && boxChecked.rain && !nextBtn ?
                <div className="btn-wrapper absolut-btn-wrapper bottom">
                    <div
                        className="game-btn start-btn hover-scale"
                        onClick={checkAnswersHandler}
                    >
                        {i18n.t("common:Buttons.check")}
                    </div>
                </div>
                : null
            }
            { nextBtn &&
                <div className="btn-wrapper absolut-btn-wrapper bottom">
                    <div
                        className="game-btn start-btn hover-scale"
                        onClick={() => nextQuestion(questionNumber + 1)}
                    >
                        {i18n.t("common:Buttons.next")}
                    </div>
                </div>
            }
        </div>
    )
}