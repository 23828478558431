import React from "react"
import {Link} from "react-router-dom";

export const EndGameScreen  = ({points, i18n}) => {
    return(
        <div className="game-container final-container">
            <div className="back-arrow">
                <Link to="/"><img src="/images/game1/arrow-back.svg" alt="" className="hover-scale"/></Link>
            </div>
            <div className="container-content">
                <div className="cup-block">
                    <img src="/images/game1/gold-cup.svg" alt=""/>
                    <div className="total-score">
                        <div><span>{i18n.t("common:Points")}</span> <p className="pixel-font large">{points}</p><p className="pixel-font">/10</p></div>
                    </div>
                </div>

            </div>
        </div>
    )
}