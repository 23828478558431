import React, {useContext, useEffect} from "react"
import {Link} from "react-router-dom"
import {LangContext} from "../../App";
import {LangPicker} from "../LangPicker";


export const DoubleCheck = () => {
    const i18n = useContext(LangContext)
    useEffect(() => {
        document.body.classList.add("body-home-page")
        return () => {
            document.body.classList.remove("body-home-page")
        }
    },[])
    const path = "/images/exponats/wallpaper/"
    return(
        <div className="exponat-page">
            <div className="exponat-page-wrapper">
                <div className="back-arrow nav-panel">
                    <Link to="/"><img src="/images/game1/arrow-back.svg" alt="" className="hover-scale"/></Link>
                    <LangPicker />
                </div>
                <div className="exponat-page__title">{i18n.t('Title.DoubleCheck')}</div>
                <p className="author">{i18n.t('AuthorText')}: {i18n.t('Author.DoubleCheck')}</p>
                <p className="default-description">{i18n.t('Description.DoubleCheck')}</p>
                <div className="pdf-preview-wrapper">
                    <span>{i18n.t('PreviewText')}:</span>
                    <a href={i18n.t('PDF.DoubleCheck')} target="_blank"><img src={i18n.t('Preview.DoubleCheck')} alt="" className="pdf-preview"/></a>
                </div>
            </div>
        </div>
    )
}
