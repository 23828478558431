import React, {useState} from "react"
import {Screen_16} from "./Screen_16";

export const Screen_15 = ({changeScreen, i18n}) => {
    const [answer, setAnswer] = useState(false)
    const [animation, setAnimation] = useState(false)

    const checkAnswerHandler = () => {
        setAnswer(true)
        setTimeout(() => {
            setAnimation(true)
        }, 300)
    }
    return(
        <div className={animation ? "waldo-game hidden-overflow" : "waldo-game"}>
            <div className="back-arrow">
                <a href="/find-waldo"><img src="/images/game1/arrow-back.svg" alt="" className="hover-scale"/></a>
                <div className="innovative-partner">
                    <span>{i18n.t("common:Innovativepartner")}</span>
                    <img src="/images/find-waldo/lenovologo-pos-black.png" alt=""/>
                </div>
            </div>
            <div className={animation ? "waldo-game__default-block default-block-wrapper"
                : "waldo-game__default-block default-block-wrapper scroll-y"}>
                <div
                    className={animation ? "waldo-game__default-block gradient-type-1 a-b-question flex-column flex-start animate"
                        : "waldo-game__default-block gradient-type-1 a-b-question flex-column flex-start"}>
                    <div className="simple-text">{i18n.t("waldogame:Screen15.text1")}</div>
                    <div className="a-b-question-block">
                        <div className="a-b-question-block__head">{i18n.t("waldogame:Screen15.text2")}</div>
                        <div className="a-b-question-block__body">
                            <div
                                onClick={checkAnswerHandler}
                                className={answer ? "question a-question error-answer answered" : "question a-question error-answer"}>
                                {answer ? <div className="error-circle"></div>
                                    : <div>a</div> }
                                {i18n.t("waldogame:Screen15.text3")}</div>
                            <div
                                onClick={checkAnswerHandler}
                                className={answer ? "question b-question error-answer answered" : "question b-question error-answer"}>
                                {answer ? <div className="error-circle"></div>
                                    : <div>b</div>}
                                {i18n.t("waldogame:Screen15.text4")}</div>
                            <div
                                onClick={checkAnswerHandler}
                                style={{marginTop:"15px"}}
                                className={answer ? "question right-answer c-question answered" : "question right-answer с-question"}>
                                {answer ? <div className="check-circle"></div>
                                    : <div>с</div>}
                                {i18n.t("waldogame:Screen15.text5")}</div>
                        </div>
                    </div>
                </div>
                {animation && <Screen_16 changeScreen={changeScreen} />}
            </div>
        </div>
    )
}
