import React, {useState} from "react"
import {NextWiget} from "../NextWiget";

export const Screen_18 = ({i18n, changeScreen}) => {

    return(
        <div className="waldo-game">
            <div className="back-arrow">
                <a href="/find-waldo"><img src="/images/game1/arrow-back.svg" alt="" className="hover-scale"/></a>
                <div className="innovative-partner">
                    <span>{i18n.t("common:Innovativepartner")}</span>
                    <img src="/images/find-waldo/lenovologo-pos-black.png" alt=""/>
                </div>
            </div>
            <div className="waldo-game__default-block no-padding" style={{overflow:"hidden"}}>
                <img src="/images/find-waldo/google-street-view.gif" alt="" className="bg-img" style={{borderRadius:"15px"}} />
                <NextWiget changeScreen={changeScreen} screen="Screen_19" />
            </div>
        </div>
    )
}