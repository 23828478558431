import React, {useContext, useEffect, useState} from "react"
import {StartGame} from "./screens/StartGame"
import {QuestionsScreen} from "./screens/QuestionsScreen"
import {InfoScreen} from "./screens/InfoScreen"
import {ScoresScreen} from "./screens/ScoresScreen"
import data from './data.json'
import info from './info.json'
import './style.scss'
import {LangContext} from "../../App";

const DeepFake = () => {
    const [screen, setScreen] = useState('StartGame')
    const [status, setStatus] = useState('')
    const [points, setPoints] = useState(0)
    const [questionNumber, setQuestionNumber] = useState(1)
    const [infoScreen, setInfoScreen] = useState(1)

    const [gradientTop, setGradientTop] = useState(false)
    const [gradientBottom, setGradientBottom] = useState(true)

    const i18n = useContext(LangContext)

    const changeScreen = (screen) => setScreen(screen)
    const changeStatus = (status) => setStatus(status)
    const changePoints = (points) => setPoints(points)
    const changeGradientTop = (activation) => setGradientTop(activation)
    const changeGradientBottom = (activation) => setGradientBottom(activation)

    const changeQuestion = (number) => {
        if(number <= data.length) {
            setQuestionNumber(number)
            setStatus('')
        }else {
            setScreen('ScoresScreen')
        }
    }

    return(
        <div className="game-field game news-tinder-game">
            {screen === 'StartGame' && <StartGame changeScreen={changeScreen} i18n={i18n} />}
            {screen === 'QuestionsScreen' &&
            <QuestionsScreen
                i18n={i18n}
                changeScreen={changeScreen}
                changeStatus={changeStatus}
                changePoints={changePoints}
                changeGradientTop={changeGradientTop}
                changeGradientBottom={changeGradientBottom}
                questionNumber={questionNumber}
                changeQuestion={changeQuestion}
                gradientTop={gradientTop}
                gradientBottom={gradientBottom}
                questions={data}
                points={points}
                status={status} />
            }
            {screen === 'InfoScreen' &&
                <InfoScreen
                    i18n={i18n}
                    info={info}
                    points={points}
                    changeScreen={changeScreen}
                    infoScreen={infoScreen}
                    setInfoScreen={setInfoScreen} />}
            {screen === 'ScoresScreen' && <ScoresScreen points={points} changeScreen={changeScreen} i18n={i18n}/>}
        </div>
    )
}

export default DeepFake