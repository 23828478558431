import axios from "axios";
import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { Route, Switch } from 'react-router-dom';
import { AIVersus } from "./components/AIVersus/AIVersus";
import BehindScreen from "./components/BehindScreen/BehindScreen";
import DeepFake from "./components/DeepFake/DeepFake";
import { Areyouhooked } from "./components/exponats/Areyouhooked";
import { CybersecurityForKids } from "./components/exponats/CybersecurityForKids";
import { Cyberstickers } from "./components/exponats/Cyberstickers";
import { DataDetoxKit } from "./components/exponats/DataDetoxKit";
import { DeepFakeLab } from "./components/exponats/DeepFakeLab";
import { DeepFuture } from "./components/exponats/DeepFuture";
import { DoubleCheck } from "./components/exponats/DoubleCheck";
import { DropInOcean } from "./components/exponats/DropInOcean";
import { Exponat } from "./components/exponats/Exponat";
import { FacebookHeritage } from "./components/exponats/FacebookHeritage";
import { FacebookReport } from "./components/exponats/FacebookReport";
import { FactChecking } from "./components/exponats/FactChecking";
import { FakeOrReal } from "./components/exponats/FakeOrReal";
import { Fishing } from "./components/exponats/Fishing";
import { GetBadNews } from "./components/exponats/GetBadNews";
import { GrabAttention } from "./components/exponats/GrabAttention";
import { Hooked } from "./components/exponats/Hooked";
import { HowBrainReactsToMisinformation } from "./components/exponats/HowBrainReactsToMisinformation";
import { MEDoc } from "./components/exponats/MEDoc";
import { RealLifeSelfie } from "./components/exponats/RealLifeSelfie";
import { RoomWithFollowers } from "./components/exponats/RoomWithFollowers";
import { StepOnlybBlackSpots } from "./components/exponats/StepOnlybBlackSpots";
import { UaReview } from "./components/exponats/UaReview";
import { UserAgreement } from "./components/exponats/UserAgreement";
import { VoiceOfCommonSense } from "./components/exponats/VoiceOfCommonSense";
import { VPNcountries } from "./components/exponats/VPNcountries";
import { WallpaperExponat } from "./components/exponats/WallpaperExponat";
import { FindWaldo } from "./components/FindWaldo/FindWaldo";
import HoldBreath from "./components/HoldBreath/HoldBreath";
import HomePage from "./components/HomePage";
import NewsTinder from "./components/NewsTinder/NewsTinder";
import { Passwords } from "./components/Passwords/Passwords";
import ReCaptcha from "./components/ReCaptcha/ReCaptcha";
import TakeQuiz from "./components/TakeQuiz/TakeQuiz";
import "./main.scss";

export const LangContext = React.createContext("");

const App = () => {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    // We listen to the resize event
    window.addEventListener('resize', () => {
        // We execute the same script as before
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    });

    const [initLang, setInitLang] = useState(null);

    //Get i18next hook
    const { i18n } = useTranslation(
        [
            'translation',
            'common',
            'versusData',
            'recaptcha',
            'newstinder',
            'holdbreath',
            'quizgame',
            'passgame',
            'behindscreen',
            'waldogame',
            'deepface'
        ]
    )

    const currLng = localStorage.getItem('i18nextLng');

    if (!initLang) {
        axios.get("https://ipinfo.io/json?token=c01ab4f2484408")
            .then(res => {
                const country = res.data.country;

                if (currLng !== country.toLowerCase()) return;

                i18n.changeLanguage(country.toLowerCase());
                setInitLang(res.data.country);
            }).catch(error => {
                setInitLang('ua');
            })
    }

    return (
        <LangContext.Provider value={i18n}>
            <Switch>
                <Route exact path='/' component={HomePage} />
                <Route exact path='/news-tinder' component={NewsTinder} />
                <Route exact path='/hold-breath' component={HoldBreath} />
                <Route exact path='/recaptcha' component={ReCaptcha} />
                <Route exact path='/aiversus' component={AIVersus} />
                <Route exact path='/passwords' component={Passwords} />
                <Route exact path='/take-a-quiz' component={TakeQuiz} />
                <Route exact path='/behind-the-screen' component={BehindScreen} />
                <Route exact path='/forgote-pass-exponat' component={Exponat} />
                <Route exact path='/find-waldo' component={FindWaldo} />
                <Route exact path='/wallpaper-exponat' component={WallpaperExponat} />
                <Route exact path='/original-or-not' component={DeepFake} />
                <Route exact path='/hooked' component={Hooked} />
                <Route exact path='/are-you-hooked' component={Areyouhooked} />
                <Route exact path='/the-real-life-selfie' component={RealLifeSelfie} />
                <Route exact path='/double-check' component={DoubleCheck} />
                <Route exact path='/deep-future' component={DeepFuture} />
                <Route exact path='/grab-your-attention' component={GrabAttention} />
                <Route exact path='/drop-in-the-ocean' component={DropInOcean} />
                <Route exact path='/facebook-report' component={FacebookReport} />
                <Route exact path='/fake-or-real' component={FakeOrReal} />
                <Route exact path='/deep-fake-lab' component={DeepFakeLab} />
                <Route exact path='/cyberstickers' component={Cyberstickers} />
                <Route exact path='/vpn-countries' component={VPNcountries} />
                <Route exact path='/get-bad-news' component={GetBadNews} />
                <Route exact path='/fact-checking' component={FactChecking} />
                <Route exact path='/data-detox-kit' component={DataDetoxKit} />
                <Route exact path='/room-with-followers' component={RoomWithFollowers} />
                <Route exact path='/user-agreement-bed-night-stories' component={UserAgreement} />
                <Route exact path='/facebook-heritage' component={FacebookHeritage} />
                <Route exact path='/not-every-malware-medoc' component={MEDoc} />
                <Route exact path='/fishing' component={Fishing} />
                <Route exact path='/cybersecurity-for-kids' component={CybersecurityForKids} />
                <Route exact path='/step-only-black-spots' component={StepOnlybBlackSpots} />
                <Route exact path='/how-brain-reacts-to-misinformation' component={HowBrainReactsToMisinformation} />
                <Route exact path='/ua-review' component={UaReview} />
                <Route exact path='/voice-of-common-sense' component={VoiceOfCommonSense} />
            </Switch>
        </LangContext.Provider>
    )
}
export default App