import React, {useEffect, useState} from "react"
import {Link} from "react-router-dom";

export const SongScreen = ({i18n ,changeScreen, songs, gradientBottom, gradientTop, onScrollHandler}) => {
    const [song, setSong] = useState(0)
    useEffect(() => {
        const number = Math.floor(Math.random() * songs.length);
        setSong(number)
    },[])
    return(
        <div className="game-container">
            <div className="back-arrow">
                <a href="/"><img src="/images/game1/arrow-back.svg" alt="" className="hover-scale"/></a>
            </div>
            <div className="container-content song-screen">
                {gradientTop && <div className="gradient top-gradient"></div>}
                <div className="container-content-wrapper" onScroll={onScrollHandler}>
                    <div className="container-title question-title">{i18n.t("quizgame:Congrat")}</div>
                    <div className="song-gif-wrapper">
                        <img src="/images/take-quiz/song.gif" alt="" className="song-gif"/>
                        <p>{i18n.t("quizgame:Songs."+ (song + 1) +".songName")}</p>
                    </div>
                    <p>{i18n.t("quizgame:Songs."+ (song + 1) +".description")}</p>
                </div>
                {gradientBottom && <div className="gradient bottom-gradient"></div>}
            </div>
            <div className="btn-wrapper">
                <div
                    className="game-btn start-btn hover-scale hold-breath-btn"
                    onClick={() => changeScreen('SliderScreen')}
                >
                    {i18n.t("common:Buttons.knowmore")}
                </div>
            </div>
        </div>
    )
}