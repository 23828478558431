import React from "react"

export const Keyboard = ({btnPress, inputRef}) => {
    return(
        <div
            className="keyboard"
            onMouseDown={(e) => btnPress(e)}>
            <div className="keyboard__item-wrapper" data-number="1">
                <div className="keyboard__item" data-number="1">
                    <div className="number">1</div>
                    <div className="letters"></div>
                </div>
            </div>
            <div className="keyboard__item-wrapper" data-number="2">
                <div className="keyboard__item" data-number="2">
                    <div className="number">2</div>
                    <div className="letters">abc</div>
                </div>
            </div>
            <div className="keyboard__item-wrapper" data-number="3">
                <div className="keyboard__item" data-number="3">
                    <div className="number">3</div>
                    <div className="letters">def</div>
                </div>
            </div>
            <div className="keyboard__item-wrapper" data-number="4">
                <div className="keyboard__item" data-number="4">
                    <div className="number">4</div>
                    <div className="letters">ghi</div>
                </div>
            </div>
            <div className="keyboard__item-wrapper" data-number="5">
                <div className="keyboard__item" data-number="5">
                    <div className="number">5</div>
                    <div className="letters">jkl</div>
                </div>
            </div>
            <div className="keyboard__item-wrapper" data-number="6">
                <div className="keyboard__item" data-number="6">
                    <div className="number">6</div>
                    <div className="letters">mno</div>
                </div>
            </div>
            <div className="keyboard__item-wrapper" data-number="7">
                <div className="keyboard__item" data-number="7">
                    <div className="number">7</div>
                    <div className="letters">pqrs</div>
                </div>
            </div>
            <div className="keyboard__item-wrapper" data-number="8">
                <div className="keyboard__item" data-number="8">
                    <div className="number">8</div>
                    <div className="letters">tuv</div>
                </div>
            </div>
            <div className="keyboard__item-wrapper" data-number="9">
                <div className="keyboard__item" data-number="9">
                    <div className="number">9</div>
                    <div className="letters">wxyz</div>
                </div>
            </div>
            <div className="keyboard__item-wrapper" data-number="0">
                <div className="keyboard__item" data-number="0">
                    <div className="number">0</div>
                    <div className="letters"></div>
                </div>
            </div>
        </div>
    )
}