import React from "react"
import {Link} from "react-router-dom"

export const QuestionsScreen = (
    {
        changeScreen,
        changeStatus,
        changeQuestion,
        changePoints,
        changeGradientTop,
        changeGradientBottom,
        questionNumber,
        gradientTop,
        gradientBottom,
        questions,
        status,
        points,
        i18n
    }) => {



    const answerHandler = (status) => {
        if(status === questions[questionNumber - 1].status) {
            changePoints(points + 1)
        }
        changeStatus(questions[questionNumber - 1].status)
    }

    const onScrollHandler = (event) => {
        const _target = event.target
        if(_target.scrollTop > 0) {
            changeGradientTop(true)
        }else {
            changeGradientTop(false)
        }

        if(_target.scrollTop === (_target.scrollHeight - _target.clientHeight)) {
            changeGradientBottom(false)
        }else {
            changeGradientBottom(true)
        }
    }

    return (
        <div className="game-container question-container news-tinder-container">
            <div className="back-arrow nav-panel">
                <Link to="/"><img src="/images/game1/arrow-back.svg" alt="" className="hover-scale"/></Link>
                <div className="question-numbering pixel-font">{questionNumber} / 20</div>
                <div className="points">
                    <img src="/images/game1/point.svg" alt=""/>
                    <span className="pixel-font">{points}</span>
                </div>
            </div>
            <div className="container-content">
                {gradientTop && <div className="gradient top-gradient"></div>}
                <div className="container-content-wrapper" onScroll={onScrollHandler}>
                    <div
                        className={questions[questionNumber - 1].img ? "container-title question-title" : "container-title question-title more-margin"}
                    >
                        {i18n.t("newstinder:"+questionNumber+".title")}
                    </div>
                    {questions[questionNumber - 1].img &&
                        <img src={questions[questionNumber - 1].img} alt="" className="illustration"/>}
                    <p className="question-content">{i18n.t("newstinder:"+questionNumber+".question")}</p>
                </div>
                {gradientBottom && <div className="gradient bottom-gradient"></div>}
            </div>
            <div className="true-false-btn">
                <div className="game-btn false-btn hover-scale" onClick={() => answerHandler('fake')}>{i18n.t("common:Fake")}</div>
                <div className="game-btn true-btn hover-scale" onClick={() => answerHandler('really')}>{i18n.t("common:True")}</div>
            </div>
            {status === 'fake' &&
                <FakePopup
                    i18n={i18n}
                    answer={i18n.t("newstinder:"+questionNumber+".answer")}
                    changeQuestion={changeQuestion}
                    questionNumber={questionNumber} />}
            {status === 'really' &&
                <ReallyPopup
                    i18n={i18n}
                    answer={i18n.t("newstinder:"+questionNumber+".answer")}
                    changeQuestion={changeQuestion}
                    questionNumber={questionNumber}/>}
        </div>
    )
}

const FakePopup = ({answer, changeQuestion, questionNumber, i18n}) => (
    <div className="status-popup fake-status">
        <div className="status-popup-wrapper">
            <div className="status-popup__content">
                <div className="title">{i18n.t("common:Fake")}</div>
                <p>{answer}</p>
                <div className="btn-wrapper">
                    <div
                        className="game-btn next-btn hover-scale"
                        onClick={() => changeQuestion(questionNumber + 1)}>
                        {i18n.t("common:Buttons.next")}
                    </div>
                </div>
            </div>
        </div>
    </div>
)

const ReallyPopup = ({answer, changeQuestion, questionNumber, i18n}) => (
    <div className="status-popup really-status">
        <div className="status-popup-wrapper">
            <div className="status-popup__content">
                <div className="title">{i18n.t("common:True")}</div>
                <p>{answer}</p>
                <div className="btn-wrapper">
                    <div
                        className="game-btn next-btn hover-scale"
                        onClick={() => changeQuestion(questionNumber + 1)}>
                        {i18n.t("common:Buttons.next")}
                    </div>
                </div>
            </div>
        </div>
    </div>
)