
import React, {useContext, useEffect} from "react"
import {Link} from "react-router-dom"
import {LangContext} from "../../App";
import {ExponatsLangPicker} from "../ExponatsLangPicker";


export const UserAgreement = () => {
    const i18n = useContext(LangContext)
    useEffect(() => {
        document.body.classList.add("body-home-page")
        return () => {
            document.body.classList.remove("body-home-page")
        }
    },[])
    const path = "/images/exponats/wallpaper/"
    return(
        <div className="exponat-page">
            <div className="exponat-page-wrapper">
                <div className="back-arrow nav-panel">
                    <Link to="/"><img src="/images/game1/arrow-back.svg" alt="" className="hover-scale"/></Link>
                    <ExponatsLangPicker />
                </div>
                <div className="exponat-page__title">{i18n.t('Title.UserAgreement')}</div>
                <p className="author">{i18n.t('Narrator')}: {i18n.t('Author.UserAgreement.narrator')}</p>
                <p className="author">{i18n.t('Director')}: {i18n.t('Author.UserAgreement.director')}</p>
                <p className="default-description">{i18n.t('Description.UserAgreement')}</p>
                <div className="pdf-preview-wrapper">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/HyxJd_TdRx0" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                </div>
            </div>
        </div>
    )
}
