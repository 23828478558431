import React, {useEffect} from "react"

export const AllowedSuccess = ({setAnswer, audioSuccess,i18n}) => {
    useEffect(() => {
        audioSuccess.play()
    },[])
    return(
        <div className="phone-block denied-allowed-wrap access-denied-phone">
            <img src="/images/passwords/success-bg.svg" alt=""/>
            <div className="phone-block__content denied-allowed-wrap__content">
                <div className="denied-allowed-title">{i18n.t("passgame:Access")}<br/>{i18n.t("passgame:Allow")}</div>
                <div className="denied-allowed-attemptions">{i18n.t("passgame:CorrectPass")}</div>
                <div className="btn-wrapper absolut-btn-wrapper password-game-btn-wrapper bottom">
                    <div
                        onClick={() => setAnswer(true)}
                        className="game-btn start-btn password-game-btn light-btn hover-scale">
                        {i18n.t("common:Buttons.continue")}
                    </div>
                </div>
            </div>
        </div>
    )
}