import React, {useState} from "react"
import {LangPicker} from "../../LangPicker";

export const Screen_1 = ({changeScreen, i18n}) => {
    const [pollAnswer, setPollAnswer] = useState(false)

    const pollAnswerHandler = () => {
        setPollAnswer(true)
        setTimeout(() => {
            changeScreen("Screen_2")
        }, 1000)
    }

    return(
        <div className="waldo-game">
            <div className="back-arrow nav-panel" style={{width:"100%"}}>
                <a href="/"><img src="/images/game1/arrow-back.svg" alt="" className="hover-scale" style={{display:"block"}}/></a>
                <div className="innovative-partner">
                    <span>{i18n.t("common:Innovativepartner")}</span>
                    <img src="/images/find-waldo/lenovologo-pos-black.png" alt=""/>
                </div>
                <LangPicker/>
            </div>
            <div className="waldo-game__default-block gradient-type-1">
                <div className="title">{i18n.t("waldogame:Screen1.title")}</div>
                <p>{i18n.t("waldogame:Screen1.text1")}</p>
                <div className="poll-block">
                    { pollAnswer ?
                        <img src={i18n.t("waldogame:Screen1.pollAnswer")} alt=""className="pool-img" />
                        : <img src={i18n.t("waldogame:Screen1.poll")} alt=""className="pool-img" onClick={pollAnswerHandler}/>
                    }
                    <img src="/images/find-waldo/postcard_1.png" alt="" className="postcard_1"/>
                </div>
            </div>
        </div>
    )
}