import React, {useContext, useEffect, useState} from "react"
import "./style.scss"
import {Screen_1} from "./screens/Screen_1";
import {Screen_2} from "./screens/Screen_2";
import {Screen_3} from "./screens/Screen_3";
import {Screen_5} from "./screens/Screen_5";
import {Screen_6} from "./screens/Screen_6";
import {Screen_8} from "./screens/Screen_8";
import {Screen_9} from "./screens/Screen_9";
import {Screen_10} from "./screens/Screen_10";
import {Screen_11} from "./screens/Screen_11";
import {Screen_12} from "./screens/Screen_12";
import {Screen_14} from "./screens/Screen_14";
import {Screen_15} from "./screens/Screen_15";
import {Screen_17} from "./screens/Screen_17";
import {Screen_18} from "./screens/Screen_18";
import {Screen_19} from "./screens/Screen_19";
import {Screen_20} from "./screens/Screen_20";
import {Screen_22} from "./screens/Screen_22";
import {Screen_24} from "./screens/Screen_24";
import {Finish} from "./screens/Finish";
import {LangContext} from "../../App";


export const FindWaldo = () => {
    const [screen, setScreen] = useState('Screen_1')

    const changeScreen = (screen) => setScreen(screen)

    const i18n = useContext(LangContext)

    useEffect(() => {
        document.body.classList.add("waldo-game-wiget")
        return () => {
            document.body.classList.remove("waldo-game-wiget")
        }
    },[])

    return(
        <div className="game-field game">
            {screen === 'Screen_1' && <Screen_1 changeScreen={changeScreen} i18n={i18n}/>}
            {screen === 'Screen_2' && <Screen_2 changeScreen={changeScreen} i18n={i18n}/>}
            {screen === 'Screen_3' && <Screen_3 changeScreen={changeScreen} i18n={i18n}/>}
            {screen === 'Screen_5' && <Screen_5 changeScreen={changeScreen} i18n={i18n}/>}
            {screen === 'Screen_6' && <Screen_6 changeScreen={changeScreen} i18n={i18n}/>}
            {screen === 'Screen_8' && <Screen_8 changeScreen={changeScreen} i18n={i18n}/>}
            {screen === 'Screen_9' && <Screen_9 changeScreen={changeScreen} i18n={i18n}/>}
            {screen === 'Screen_10' && <Screen_10 changeScreen={changeScreen} i18n={i18n}/>}
            {screen === 'Screen_11' && <Screen_11 changeScreen={changeScreen} i18n={i18n}/>}
            {screen === 'Screen_12' && <Screen_12 changeScreen={changeScreen} i18n={i18n}/>}
            {screen === 'Screen_14' && <Screen_14 changeScreen={changeScreen} i18n={i18n}/>}
            {screen === 'Screen_15' && <Screen_15 changeScreen={changeScreen} i18n={i18n}/>}
            {screen === 'Screen_17' && <Screen_17 changeScreen={changeScreen} i18n={i18n}/>}
            {screen === 'Screen_18' && <Screen_18 changeScreen={changeScreen} i18n={i18n}/>}
            {screen === 'Screen_19' && <Screen_19 changeScreen={changeScreen} i18n={i18n}/>}
            {screen === 'Screen_20' && <Screen_20 changeScreen={changeScreen} i18n={i18n}/>}
            {screen === 'Screen_22' && <Screen_22 changeScreen={changeScreen} i18n={i18n}/>}
            {screen === 'Screen_24' && <Screen_24 changeScreen={changeScreen} i18n={i18n}/>}
            {screen === 'Finish' && <Finish changeScreen={changeScreen} i18n={i18n}/>}
        </div>
    )
}