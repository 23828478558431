import React, {useContext, useState} from "react"
import {StartGame} from "./screens/StartGame"
import {ChapterTwo} from "./screens/chapters/ChapterTwo"
import {ChapterOne} from "./screens/chapters/ChapterOne"
import {ChapterThree} from "./screens/chapters/ChapterThree"
import {ChapterFour} from "./screens/chapters/ChapterFour"
import {ChapterFive} from "./screens/chapters/ChapterFive"
import {CaptchaPracticeScreen} from "./screens/CaptchaPracticeScreen"
import "./style.scss"
import {LangContext} from "../../App";

const ReCaptcha = () => {
    const [screen, setScreen] = useState('StartGame')
    const [chapter, setChapter] = useState(1)

    const changeScreen = (screen) => setScreen(screen)
    const changeChapter = (number) => setChapter(number)

    const i18n = useContext(LangContext)

    const screenHalfDetecter = () => {
        var whalf = window.innerWidth / 2,
            middleLeft = whalf - 300,
            middleRight = whalf + 300;
        // document.body.classList.remove('nocursor')
        // document.onmousemove = function(event) {
        //     if(event.pageX > middleLeft && event.pageX < middleRight) {
        //         document.body.classList.remove('right-cursor')
        //         document.body.classList.remove('left-cursor')
        //     }else {
        //         if (event.pageX < whalf) {
        //             document.body.classList.remove('right-cursor')
        //             document.body.classList.add('left-cursor')
        //         } else {
        //             document.body.classList.remove('left-cursor')
        //             document.body.classList.add('right-cursor')
        //         }
        //     }
        // }
    }

    const screenHalfDetecterRemove = () => {
        document.body.classList.add('nocursor')
    }


    return(
        <div className="game-field game">
            {screen === 'StartGame' && <StartGame changeScreen={changeScreen} i18n={i18n}/>}
            {screen === 'ChapterOne' && <ChapterOne
                    screenHalfDetecter={screenHalfDetecter}
                    i18n={i18n}
                    slide={0}
                    slides={12}
                    changeScreen={changeScreen} />}
            {screen === 'CaptchaPracticeScreen' &&
                    <div>
                        {chapter === 1 && <ChapterOne changeScreen={changeScreen} screenHalfDetecter={screenHalfDetecter} slide={10} i18n={i18n}/>}
                        {chapter === 2 && <ChapterTwo changeScreen={changeScreen} screenHalfDetecter={screenHalfDetecter} slide={14} i18n={i18n}/>}
                        {chapter === 3 && <ChapterThree changeScreen={changeScreen} screenHalfDetecter={screenHalfDetecter} slide={14} i18n={i18n}/>}
                        {chapter === 4 && <ChapterFour changeScreen={changeScreen} screenHalfDetecter={screenHalfDetecter} slide={8} i18n={i18n}/>}
                        {chapter === 5 && <ChapterFive changeScreen={changeScreen} screenHalfDetecter={screenHalfDetecter} slide={10} i18n={i18n}/>}
                        <CaptchaPracticeScreen chapter={chapter} changeScreen={changeScreen} screenHalfDetecterRemove={screenHalfDetecterRemove} i18n={i18n}/>
                    </div>}
            {screen === 'ChapterTwo' &&
                    <div>
                        <CaptchaPracticeScreen chapter={chapter} changeScreen={changeScreen} screenHalfDetecterRemove={screenHalfDetecterRemove} i18n={i18n}/>
                        <ChapterTwo
                            screenHalfDetecter={screenHalfDetecter}
                            slide={0}
                            slides={16}
                            i18n={i18n}
                            changeChapter={changeChapter}
                            changeScreen={changeScreen}  />
                    </div>}
            {screen === 'ChapterThree' &&
                    <div>
                        <CaptchaPracticeScreen chapter={chapter} changeScreen={changeScreen} screenHalfDetecterRemove={screenHalfDetecterRemove} i18n={i18n} />
                        <ChapterThree
                            screenHalfDetecter={screenHalfDetecter}
                            slide={0}
                            slides={16}
                            i18n={i18n}
                            changeChapter={changeChapter}
                            changeScreen={changeScreen}  />
                    </div>}
            {screen === 'ChapterFour' &&
                    <div>
                        <CaptchaPracticeScreen chapter={chapter} changeScreen={changeScreen} screenHalfDetecterRemove={screenHalfDetecterRemove} i18n={i18n}/>
                        <ChapterFour
                            screenHalfDetecter={screenHalfDetecter}
                            slide={0}
                            slides={10}
                            i18n={i18n}
                            changeChapter={changeChapter}
                            changeScreen={changeScreen}  />
                    </div>}
            {screen === 'ChapterFive' &&
                    <div>
                        <CaptchaPracticeScreen chapter={chapter} changeScreen={changeScreen} screenHalfDetecterRemove={screenHalfDetecterRemove} i18n={i18n}/>
                        <ChapterFive
                            screenHalfDetecter={screenHalfDetecter}
                            slide={0}
                            slides={13}
                            i18n={i18n}
                            changeChapter={changeChapter}
                            changeScreen={changeScreen}  />
                    </div>}
        </div>
    )
}

export default ReCaptcha