import React, {useContext, useState} from "react"
import {StartGame} from "./screens/StartGame";
import {FbScreen} from "./screens/FbScreen";
import {TestScreen} from "./screens/TestScreen";
import {SongScreen} from "./screens/SongScreen";
import {SliderScreen} from "./screens/sliders/SliderScreen";
import {PolygonScreen} from "./screens/PolygonScreen";
import {EndScreen} from "./screens/EndScreen";
import data from "./data.json"
import songs from "./songs.json"
import sliders from "./sliders.json"
import "./style.scss"
import {LangContext} from "../../App";

const TakeQuiz = () => {
    const [screen, setScreen] = useState('StartGame')
    const [slider, setSlider] = useState(0)
    const [polygonText, setPolygonText] = useState('')
    const [testQuestion, setTestQuestion] = useState(1)
    const [gradientTop, setGradientTop] = useState(false)
    const [gradientBottom, setGradientBottom] = useState(true)

    const changeScreen = (screen) => setScreen(screen)


    const onScrollHandler = (event) => {
        const _target = event.target
        if(_target.scrollTop > 0) {
            setGradientTop(true)
        }else {
            setGradientTop(false)
        }

        if(_target.scrollTop === (_target.scrollHeight - _target.clientHeight)) {
            setGradientBottom(false)
        }else {
            setGradientBottom(true)
        }
    }

    const i18n = useContext(LangContext)


    return(
        <div className={screen === 'FbScreen' ? "game-field take-a-quiz game fb-screen" : "game-field take-a-quiz game"}>
            {screen === 'StartGame' && <StartGame changeScreen={changeScreen} i18n={i18n} />}
            {screen === 'FbScreen' &&
                <FbScreen
                    i18n={i18n}
                    gradientBottom={gradientBottom}
                    gradientTop={gradientTop}
                    onScrollHandler={onScrollHandler}
                    changeScreen={changeScreen} />}

            {screen === 'TestScreen' &&
                <TestScreen
                    i18n={i18n}
                    data={data}
                    testQuestion={testQuestion}
                    setTestQuestion={setTestQuestion}
                    changeScreen={changeScreen} />}
            {screen === 'SongScreen' &&
                <SongScreen
                    i18n={i18n}
                    songs={songs}
                    gradientBottom={gradientBottom}
                    gradientTop={gradientTop}
                    onScrollHandler={onScrollHandler}
                    changeScreen={changeScreen} /> }
            {screen === 'SliderScreen' &&
                <SliderScreen
                    i18n={i18n}
                    sliders={sliders}
                    slider={slider}
                    setPolygonText={setPolygonText}
                    changeScreen={changeScreen} /> }

            {screen === 'PolygonScreen' &&
                <PolygonScreen
                    i18n={i18n}
                    sliders={sliders}
                    slider={slider}
                    setSlider={setSlider}
                    polygonText={polygonText}
                    changeScreen={changeScreen} /> }
            {screen === 'EndScreen' &&
                <EndScreen
                    i18n={i18n}
                    changeScreen={changeScreen} /> }
        </div>
    )
}

export default TakeQuiz