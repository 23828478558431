import React, {useState} from "react"
import {NextWiget} from "../NextWiget";
import {Prompt} from "../Prompt";

export const Screen_19 = ({changeScreen, i18n}) => {
    const [prompt, setPrompt] = useState(true)

    return(
        <div className="waldo-game">
            {prompt && <Prompt setPrompt={setPrompt} text={i18n.t("waldogame:Screen19.prompt")}/>}
            <div className="back-arrow">
                <a href="/find-waldo"><img src="/images/game1/arrow-back.svg" alt="" className="hover-scale"/></a>
                <div className="innovative-partner">
                    <span>{i18n.t("common:Innovativepartner")}</span>
                    <img src="/images/find-waldo/lenovologo-pos-black.png" alt=""/>
                </div>
            </div>
            <div className="waldo-game__default-block no-padding scroll-y">
                <img src="/images/find-waldo/bg_6.jpg" alt="" className="bg-img"/>
                <NextWiget changeScreen={changeScreen} screen="Screen_20" />
            </div>
        </div>
    )
}