import React from "react"

export const EndGame = ({score, i18n}) => {
    return(
        <div className="container-content">
            <div className="container-content-wrapper">
                <div className="phone-block">
                    <img src="/images/passwords/final-bg.svg" alt=""/>
                    <div className="phone-block__content end-game-content">
                        <div className="pixel-font score-box">{score}/7</div>
                        <span>{i18n.t("passgame:GuessPass")}</span>
                        <p>{i18n.t("passgame:EndText")}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}