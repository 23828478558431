import React, {useState} from "react"
import {Link} from "react-router-dom";


const ProtectionScreen = ({i18n, changeScreen, gradientBottom, gradientTop, onScrollHandler}) => {
    return(
        <div className="protection-screen">
            <div className="game-container pseudo-container">
                <div className="back-arrow">
                    <Link to="/"><img src="/images/game1/arrow-back.svg" alt="" className="hover-scale"/></Link>
                </div>
                <div className="container-content">
                    {gradientTop && <div className="gradient top-gradient"></div>}
                    <div className="container-content-wrapper" onScroll={onScrollHandler}>
                        <div className="container-title small-screen">{i18n.t("quizgame:FbText.1")}</div>
                        <img src="/images/take-quiz/seriously.gif" alt="" className="seriously-gif"/>
                        <p>{i18n.t("quizgame:FbText.2")}</p>
                    </div>
                    {gradientBottom && <div className="gradient bottom-gradient"></div>}
                </div>
                <div className="btn-wrapper">
                    <div
                        className="game-btn start-btn hover-scale"
                        onClick={() => changeScreen('TestScreen')}
                    >
                        {i18n.t("common:Buttons.onstart")}
                    </div>
                </div>
            </div>
        </div>
    )
}

export const FbScreen = ({i18n, changeScreen, gradientBottom, gradientTop, onScrollHandler}) => {
    const [protectScreen, setProtectScreen] = useState(false)
    return(
        <div className="game-container">
            <div className="fb-page-block">
                <div className="container-title">{i18n.t("quizgame:FbText.3")}</div>
                <p>{i18n.t("quizgame:FbText.4")}</p>
                <div className="fake-fb-btn" onClick={() => setProtectScreen(true)}>
                    <img src="/images/take-quiz/fb-icon.svg" alt=""/>
                    {i18n.t("quizgame:FbText.5")}
                </div>
            </div>
            {protectScreen &&
                <ProtectionScreen
                    i18n={i18n}
                    gradientBottom={gradientBottom}
                    gradientTop={gradientTop}
                    onScrollHandler={onScrollHandler}
                    changeScreen={changeScreen}/>}
        </div>
    )
}